import React, { Component } from 'react';




export default class Footer extends Component {
  render() {
    return (
      <div className="footer1-wrap">
      <br/>
        <div className="footer1">© Engineering Connections LLC dba <a href="https://startup-systems.com/">Startup Systems</a>, all rights reserved.</div>
        <br/>
      </div>
    );
  }
}




