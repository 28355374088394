import axios from "axios";
import { decodeJwtToken } from '../utils/index';
import { CREATE_USER_SUCCESS,
          CREATE_USER_FAILURE,
          LOGIN_USER_SUCCESS,
          LOGIN_USER_FAILURE,
          UPDATE_USER_SUCCESS,
          UPDATE_USER_FAILURE,          
          FOLLOW_USER_SUCCESS,
          FOLLOW_USER_FAILURE,
          UNFOLLOW_USER_SUCCESS,
          UNFOLLOW_USER_FAILURE, 
          GET_USER_SUCCESS,
          GET_USER_FAILURE,
          GET_PUBLIC_PROFILE_SUCCESS,
          GET_PUBLIC_PROFILE_FAILURE,
          DELETE_FOLLOWER_SUCCESS,
          DELETE_FOLLOWER_FAILURE,
          GET_ALL_USERS_SUCCESS,
          GET_ALL_USERS_FAILURE} from "./actionTypes";

export const createUser = body => {
  return dispatch => {
    return axios({
      url:
        "/api/users/register",
      method: "POST",
      data: body
    })
      .then((response) => {
        let userInfo;
        if (response.data.success) {
          userInfo = decodeJwtToken(response.data.token);
          localStorage.setItem('username', userInfo.username);
          localStorage.setItem('token', response.data.token);          
          dispatch({ type: CREATE_USER_SUCCESS, payload: userInfo });
        } else {
          dispatch({ type: CREATE_USER_FAILURE, payload: response.data });
        }
        return userInfo;
      })
      .catch((err) => {
        dispatch({ type: CREATE_USER_FAILURE, payload: err.response.data.error });
        return Promise.reject(err.response.data.error);
      });
  };
};

export const loginUser = body => {
  return dispatch => {
    return axios({
      url:
        "/api/users/login",
      method: "POST",
      data: body
    })
      .then(function (response) {
        let userInfo;
        if (response.data.success) {
          userInfo = decodeJwtToken(response.data.token);
          localStorage.setItem('username', userInfo.username);
          localStorage.setItem('token', response.data.token);                    
          dispatch({ type: LOGIN_USER_SUCCESS, response: userInfo });
        } else {
          dispatch({ type: LOGIN_USER_FAILURE, error: {} });
        }
        return userInfo;
      })
      .catch(err => dispatch({ type: LOGIN_USER_FAILURE, error: {} }));
  };
};

export const getAllUsers = () => {
  return (dispatch) => {
    return axios.get(`/api/users`, {
      headers: {
        token: localStorage.getItem('token')
      }      
    }).then(res => {
      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: GET_ALL_USERS_FAILURE,
        payload: err
      });
      return err;
    });
  }
}

export const getUserInfo = (username) => {
  return dispatch => {
    return axios.get(`/api/users/${username}`).then(res => {
      dispatch({
        type: GET_PUBLIC_PROFILE_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: GET_PUBLIC_PROFILE_FAILURE,
        payload: err
      });
      return Promise.reject(err);
    });
  }  
}

export const getLoggedInUser = () => {
  const username = localStorage.getItem('username');
  return (dispatch) => {
    return axios.get(`/api/users/${username}`, {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: GET_USER_FAILURE,
        payload: err
      });
      return err;
    });
  }  
}

export const updateUser = (userInfo) => {
  return dispatch => {
    return axios.patch(`/api/users/${userInfo.username}`, userInfo, {
      headers: {
        token: localStorage.getItem('token')
      }      
    }).then(res => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: err
      });
      return Promise.reject(err.response.data);
    });
  }
}

export const followUser = userToFollow => {
  return (dispatch) => {
    return axios.get(`/api/users/follow/${userToFollow}`, {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {
      dispatch({
        type: FOLLOW_USER_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: FOLLOW_USER_FAILURE,
        payload: err
      });
      return err;
    });
  }
}

export const unfollowUser = userToUnfollow => {
  return (dispatch) => {
    return axios.get(`/api/users/unfollow/${userToUnfollow}`, {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {     
      dispatch({
        type: UNFOLLOW_USER_SUCCESS,
        payload: res.data
      });
      return userToUnfollow;
    }).catch(err => {
      dispatch({
        type: UNFOLLOW_USER_FAILURE,
        payload: err
      });
      return err;
    });
  }
}

export const deleteFollower = user => {
  return (dispatch) => {
    return axios.get(`/api/users/delete-follower/${user}`, {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {     
      dispatch({
        type: DELETE_FOLLOWER_SUCCESS,
        payload: res.data
      });
      return user;
    }).catch(err => {
      dispatch({
        type: DELETE_FOLLOWER_FAILURE,
        payload: err
      });
      return err;
    });
  }
}