import React, { Component } from 'react';

export default class Install extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Privacy logo-shift">
        <br />
        <h2 className="App mb-5">Android, iPhone, Windows, Mac, Linux, and Chromebook Installation Options/Instructions</h2>
        <br />
        <h3 className='App'>Android Installation (Google Play Store Installation)</h3>
        <br />
        <h4>
          1.  Visit MovieCritic Google Play Store Link Below:<br />
          <a href='https://play.google.com/store/apps/details?id=com.startupsystems.moviecriticapp'>https://play.google.com/store/apps/details?id=com.startupsystems.moviecriticapp</a><br />
          2.  Click "Install" button.<br />
          3.  Open/start MovieCritic App from Android Apps or Home Screen.<br />
          <br />
        </h4>
        <h3 className='App'>Android Installation (Add To Home Screen Installation)</h3>
        <br />
        <h4>
          1.  Visit https://www.moviecriticapp.com/ in default android browser (ex. chrome for android).<br />
          2.  Select browser options (ex. chrome, click on the 3 dots icon on top right of browser) <br />
          3.  Click "Add To Home Screen", then click "Add". <br />
          4.  MovieCritic should now be installed in android apps, and can be started by clicking the <br />
          MovieCritic app icon in android apps or from the home screen.<br />
        </h4>
        <br />
        <h3 className='App'>iPhone Installation (Add To Home Screen Installation)</h3>
        <br />
        <h4>
          1.  Visit https://www.moviecriticapp.com/ in default iphone browser (ex. safari). <br />
          2.  Select browser options (ex. safari, click on square with up arrow on bottom center of browser) <br />
          3.  Click "Add To Home Screen", then click "Done". <br />
          4.  MovieCritic should now be installed on iPhone home screen, and can be started by clicking the <br />
          MovieCritic app icon from the home screen.<br />
        </h4>
        <br />
        <h3 className='App'>Windows, Mac, Linux, Chromebook Installation (Chrome Address Bar Installation Icon)</h3>
        <br />
        <h4>
          1.  Visit https://www.moviecriticapp.com/ in Google's Chrome browser on Windows, Mac, or Linux. <br />
          2.  Click the install (+) button in Chrome's address bar on the right. <br />
          3.  Click the "Install" button that pops up. <br />
          4.  Done, MovieCritic should now be installed and appear in your computer's taskbar/app dock. Just click the app to start it.<br />
          5.  Optional: Pin the MovieCritic app to your taskbar (Ex. Windows, right click app, select "pin to taskbar").<br />
        </h4>
        <br />
        <h3 className='App'>Updating/Closing/Restarting Mobile Apps</h3>
        <br />
        <h4>
          1.  To close the Android app, tap the device's back button once or more until the app is closed. <br />
          2.  To close the iPhone app, tap the device's home/menu button. <br />
          3.  To start/restart the app, click the app icon that was added to the device's home screen.  <br />
          4.  To update the Android app, close and restart it. <br />
          5.  To update the iPhone app, delete/remove it from the home screen, open Safari browser on iPhone, close the current Safari tab (press the double square icon, then x icon on top left of Safari tab), open a new Safari tab, visit moviecriticapp.com in the new Safari tab, then re-add the app to home screen from Safari's options menu and start/click the updated app. <br />
          6.  If the app crashes for any reason, simply close and restart the app.<br />
        </h4>
        <br />
      </div>
    );
  }
}




