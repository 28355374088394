import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import { importMovies, importMoviesJson, getUserMovies } from '../../actions/movieActions';

class MovieForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usernameToImport: '',
            jsonFile: '',
            showAlert: false,
            alertMsg: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sweetAlertConfirm = this.sweetAlertConfirm.bind(this);
    }

    componentWillMount() {
        if (!localStorage.getItem('username')) {
            this.props.history.replace('/');
        }
    }    

    handleSubmit(event, type) {
        event.preventDefault();
        const username = localStorage.getItem('username');
        const {usernameToImport, jsonFile} = this.state;
        if (type === 'export') {
            this.props.getUserMovies(username).then(movies => {
                FileDownload(JSON.stringify(movies, null, 2), 'my-movies.json');
            });
        } else if (type === 'import-user' && usernameToImport) {
            this.props.importMovies(usernameToImport);
        } else if(type === 'import-json') {
            if(jsonFile) {
                this.props.importJson(jsonFile).then(resp => {
                    if (resp.err) {
                        this.setState({showAlert: true, alertMsg: `Failed to import movie reviews.`}, () => setTimeout(() => this.setState({showAlert: false}), 1000));
                    } else {
                        this.setState({showAlert: true, alertMsg: `Successfully imported movie reviews.`}, () => setTimeout(() => this.setState({showAlert: false}), 1000));
                    }
                });
            }
        }
    }

    handleChange(event, type) {
        if (type === 'import-user') {
            this.setState({ username: event.target.value });
        } else {
            this.setState({ jsonFile: event.target.files[0] });
        }
    }

    sweetAlertConfirm = () => {
        this.setState({ username: '', showAlert: false });
    }

    render() {
        const {showAlert, alertMsg} = this.state;
        return (
            <div className="container">
                <h2 className="App">Import/Export Your Movie/Show Reviews</h2>
                <br/>
                <SweetAlert
                    show={showAlert}
                    title={alertMsg}
                    onConfirm={this.sweetAlertConfirm}
                />
               {/*  <h3>Import Movie/Show Reviews From Another User:</h3>
                <form onSubmit={(event) => this.handleSubmit(event, 'import-user')}>
                    <div className="form-group">
                        <input type="text"
                               name="user-email"
                               required
                               placeholder="Enter Username"
                               value={this.state.username}
                               onChange={(e) => this.handleChange(e, 'import-user')}
                               style={{ marginRight: '20px' }}/>
                        <button type="submit" className="btn btn-outline-primary float-right">Import</button>
                    </div> </form> */}
           
                <h3>Import Movie/Show Reviews From Exported File (JSON Format):</h3>
                <form onSubmit={(event) => this.handleSubmit(event, 'import-json')}
                      encType="multipart/form-data">
                    <div className="form-group">
                        <input type="file"
                               name="json-file"
                               required
                               onChange={(e) => this.handleChange(e, 'import-json')}
                               accept=".json"
                               style={{ marginRight: '20px' }}/>
                        <button type="submit" className="btn btn-outline-primary float-right btn-lg">Import</button>
                    </div>
                </form>                             
                <h3>Export Movie/Show Reviews To File (JSON Format):</h3>
                <form onSubmit={(event) => this.handleSubmit(event, 'export')}>
                    <div className="form-group">
                        <label style={{marginRight: '20px'}}>Export your movie/show reviews by clicking the "export" button. </label>
                        <button type="submit"
                            className="btn btn-outline-primary float-right btn-lg">Export</button>
                    </div>
                </form>
                <hr />
            </div>
        );
    }
}


const mapStateToProps = (store) => {
    return {
        user: store.user,
        movies: store.movie.movies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserMovies: (username) => dispatch(getUserMovies(username)),
        importMovies: (myEmail, username) => dispatch(importMovies(myEmail, username)),
        importJson: (formData) => dispatch(importMoviesJson(formData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieForm)
