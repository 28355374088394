import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Bowser from "bowser";
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../node_modules/sweetalert/dist/sweetalert.css'
import { loginUser, getLoggedInUser } from '../actions/userActions';

const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const osName = browser.getOSName();
const browserIsSupported = browserName === 'Chrome' && osName !== 'iOS';

class Login extends React.Component {
  state = {
    usernameOrEmail: '',
    password: '',
    showAlert: false,
    alertMsg: '',
    alertTitle: '',
    showCancelButton: false,
    recognitionStarted: false
  }

  componentWillMount() {
   /*  if (localStorage.getItem('token') && localStorage.getItem('username')) {
      this.props.history.replace('/home');
    } */
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (browserIsSupported) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
      const grammar = '#JSGF V1.0;'
      this.recognition = new SpeechRecognition();
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      this.recognition.grammars = speechRecognitionList;
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = false;

      this.recognition.onresult = event => {
        const last = event.results.length - 1;
        const command = event.results[last][0].transcript.replace(/\s/g, '').toLowerCase();
        this.handleInputChange('usernameOrEmail', command);
      };

      this.recognition.onspeechend = () => {
        this.setState({ showAlert: false });
        { browserIsSupported && this.recognition.stop(); }
      };

      this.recognition.onstart = () => {
        this.setState({ recognitionStarted: true });
      }
    }
  }


  handleVoiceRecognition = () => {
    const { recognitionStarted } = this.state;
    this.setState({ showAlert: true, alertMsg: 'Listening...' }, () => {
      !recognitionStarted && setTimeout(() => this.recognition.start(), 100);
    });
  }

  loginUser = (e) => {
    e.preventDefault();
    const { usernameOrEmail, password } = this.state;
    const body = { usernameOrEmail, password };

    this.props.loginUser(body).then(response => {
      const userInfo = response;
      if (userInfo && userInfo.username) {
        this.props.getLoggedInUser();
        this.props.history.replace('/home');
      } else {
        this.setState({ showAlert: true, alertMsg: '', alertTitle: 'Invalid Username/Password Combination. \n Would you like to reset your Password?', showCancelButton: true });
      }
    }).catch(err => console.log('err during login', err));
  }

  handleInputChange = (prop, value) => {
    this.setState({ [prop]: value })
  }

  render() {
    const { usernameOrEmail, password, showAlert, alertMsg, showCancelButton, alertTitle } = this.state;
    return (
      <div className="logo-shift wideFix">
        <h1>Login</h1>
        <br />
        <h4>Welcome to MovieCritic, a cross-platform social network to share and discover movie/show reviews.</h4>
        <hr />
        <SweetAlert
          show={showAlert}
          title={alertTitle}
          html
          text={renderToStaticMarkup(<h3>{alertMsg}</h3>)}
          showCancelButton={showCancelButton}
          onConfirm={() => {
            browserIsSupported && this.recognition && this.recognition.stop();
            this.setState({ showAlert: false, recognitionStarted: false });
            if (alertTitle) {
              this.props.history.replace('/begin-password-reset');
            }
          }}
          onCancel={() => {
            browserIsSupported && this.recognition && this.recognition.stop();
            this.setState({ showAlert: false, recognitionStarted: false });
          }}
          onOutsideClick={() => {
            browserIsSupported && this.recognition && this.recognition.stop();
            this.setState({ showAlert: false, recognitionStarted: false });
          }}
        />
        <form onSubmit={this.loginUser}>
          <label htmlFor="usernameOrEmail">Username</label>
          <div className="input-group">
            <input autoComplete="off" autoCorrect="off" autoCapitalize="none" required type="text" className="form-control" name="usernameOrEmail" aria-describedby="usernameOrEmailHelp" placeholder="Please Enter Your Username." value={usernameOrEmail} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
            {browserIsSupported && <span className="input-group-addon ml-2">
              <i onClick={() => this.handleVoiceRecognition()} className="fas fa-2x fa-microphone"></i>
            </span>}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input autoComplete="off" autoCorrect="off" autoCapitalize="none" required type="password" className="form-control" name="password" placeholder="Password" value={password} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
          </div>
          <button type="submit" className="btn btn-outline-primary">Login</button>
        </form>
        <div>
          <br />
          <h4>Don't have an account yet? <Link to="/register">Register</Link> here, free.</h4><br/>
          <h4>Test the app without registering by entering "demo" as username and password.</h4><br/>
        </div>
        <br/>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (body) => dispatch(loginUser(body)),
    getLoggedInUser: () => dispatch(getLoggedInUser())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
