import {
    ADD_MOVIE_SUCCESS,
    ADD_MOVIE_FAILURE,
    GET_USER_MOVIES_SUCCESS,
    GET_USER_MOVIES_FAILURE,
    GET_FOLLOWING_MOVIES_SUCCESS,
    GET_FOLLOWING_MOVIES_FAILURE,
    UPDATE_MOVIE_SUCCESS,
    UPDATE_MOVIE_FAILURE,
    DELETE_MOVIE_SUCCESS,
    DELETE_MOVIE_FAILURE,
    IMPORT_MOVIES_SUCCESS,
    IMPORT_MOVIES_FAILURE,
    GET_ONLINE_MOVIES_SUCCESS,
    GET_ONLINE_MOVIES_FAILURE
} from '../actions/actionTypes';

const initialState = {
    movies: [],
    followingMovies: [],
    onlineMovies: [],
    error: ''
}

const movieReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MOVIE_SUCCESS:
            state.movies.unshift(action.payload)
            return JSON.parse(JSON.stringify({ ...state }));
        case ADD_MOVIE_FAILURE:
            return {...state, error: action.payload};
        case GET_USER_MOVIES_SUCCESS:
            return JSON.parse(JSON.stringify({ ...state, movies: action.payload.reverse() }));
        case GET_USER_MOVIES_FAILURE:
            return action.payload;
        case GET_ONLINE_MOVIES_SUCCESS:
            return JSON.parse(JSON.stringify({ ...state, onlineMovies: action.payload.reverse() }));
        case GET_ONLINE_MOVIES_FAILURE:
            return {...state, error: action.payload};
        case GET_FOLLOWING_MOVIES_SUCCESS:
            return { ...state, followingMovies: action.movies.reverse() };
        case GET_FOLLOWING_MOVIES_FAILURE:
            return action.error;
        case UPDATE_MOVIE_SUCCESS:
            const movieIndex = state.followingMovies.findIndex(m => m._id === action.payload._id);
            state.followingMovies.splice(movieIndex, 1, action.payload);
            const movieIndex2 = state.movies.findIndex(m => m._id === action.payload._id);
            state.movies.splice(movieIndex2, 1, action.payload);
            return JSON.parse(JSON.stringify({ ...state }));
        case UPDATE_MOVIE_FAILURE:
            return JSON.parse(JSON.stringify({...state, error: action.payload}));
        case DELETE_MOVIE_SUCCESS:
            return { ...state, movies: state.movies.filter(m => m._id !== action.movie.id) };
        case DELETE_MOVIE_FAILURE:
            return action.error;
        case IMPORT_MOVIES_SUCCESS:
            return { ...state, movies: state.movies.concat(action.movies).reverse() };
        case IMPORT_MOVIES_FAILURE:
            return action.payload;
        default:
            return state;
    }
}

export default movieReducer
