import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserMovies } from '../../actions/movieActions';
import MovieForm from './MovieForm';
import MovieList from './MovieList';
import { capitalizeFirstLetter } from '../../utils';

class WatchedMovies extends Component {
  state = {
    rating: null,
    type: null,
    whereToShare: '',
    id: null,
    mode: 'Add',
    title: null,
    titleDisabled: false
  };

  componentWillMount() {
    if (!localStorage.getItem('username')) {
      this.props.history.replace('/');
    }
  }

  componentDidMount() {
    if (localStorage.getItem('username')) {
      this.props.getUserMovies(localStorage.getItem('username'));
    }
  }

  handleInputChange = (newState) => {
    this.setState(newState)
  }

  editMovie = (movie) => {
    const { mode, titleDisabled } = this.state;
    if (mode === 'Add') {
      const { rating, title, type } = movie;
      this.setState({ title: { label: title, value: title }, titleDisabled: !titleDisabled, rating: {label: rating === 0 ? 'Bomb' : rating, value: rating}, type: {label: capitalizeFirstLetter(type), value: type}, id: movie._id, mode: 'Update' });
      window.scrollTo(0, 0)
    } else {
      this.setState({ mode: 'Add', title: '', rating: '', type: '', titleDisabled: !titleDisabled });
    }
  }

  render() {
    const { title, type, id, rating, mode, whereToShare, foundMovies, searchInput, menuIsOpen, titleDisabled } = this.state;
    const { movies } = this.props;
    return (
      <div>
        <h2 className="App">My Movie/Show Reviews</h2>
        <br />
        <MovieForm titleDisabled={titleDisabled} showShareButton showShareReview showType showRating movieId={id} handleInputChange={this.handleInputChange} title={title} type={type} rating={rating} mode={mode} whereToShare={whereToShare} editMovie={this.editMovie} menuIsOpen={menuIsOpen} searchInput={searchInput} foundMovies={foundMovies} />
        <MovieList showShare showRating showRating2 showType showDeleteMovie movies={movies} editMovie={this.editMovie} history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    movies: (store.movie.movies || []).filter(m => m.watched).sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMovies: (username) => dispatch(getUserMovies(username))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchedMovies);
