import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor'
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import { followUser, loginUser, getUserInfo } from '../actions/userActions';
import { getUserMovies } from '../actions/movieActions';
import MovieList from './Movie/MovieList';
import { capitalizeFirstLetter } from '../utils';

class ProfilePage extends Component {
  state = {
    usernameOrEmail: '',
    password: '',
    format: 'csv',
    moviesToDownload: [],
    moviesToDisplay: [],
    profileUser: { followers: [] },
    alertTitle: ''
  }

  componentDidMount() {
    const { username } = this.props.match.params;
    document.title = `MovieCritic Profile - ${username}`;
    this.fetchUserMovies(username);
  }

  fetchUserMovies = async username => {
    this.props.getUserInfo(username).then(profileUser => {
      this.props.getUserMovies(username).then(movies => {
        movies = movies.filter(m => m.watched).sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
        const mappedMovies = movies.map(m => {
          return {
            username: m.userId,
            title: m.title,
            type: m.type,
            rating: m.rating,
            'Type': m.type,
            createdOn: moment(m.createdAt).format("MMMM Do YYYY - h:mm a")
          }
        })
        return this.setState({ moviesToDownload: mappedMovies, moviesToDisplay: movies, profileUser });
      });
    });
  }

  renderLoginForm = () => {
    const { usernameOrEmail, password } = this.state;
    return (
      <div>
        <h2>Please log in and follow this user to view/download their reviews.</h2>
        <br />
        <form onSubmit={this.loginUser}>
          <div className="form-group">
            <label htmlFor="usernameOrEmail">Username/Email</label>
            <input type="text" className="form-control" id="usernameOrEmail" aria-describedby="usernameOrEmailHelp" placeholder="Enter username" value={usernameOrEmail} onChange={this.handleInput} required />
            <small id="usernameOrEmailHelp" className="form-text text-muted">Please enter your username.</small>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={this.handleInput} required />
          </div>
          <button type="submit" className="btn btn-primary">Login</button>
        </form>
        <div>
          <p>Don't have an account yet? <Link to="/">Register</Link>.</p>
        </div>
      </div>
    )
  }

  loginUser = (e) => {
    e.preventDefault();
    const { usernameOrEmail, password } = this.state;
    const body = { usernameOrEmail, password };

    this.props.loginUser(body).then(response => {
      const userInfo = response;
      if (userInfo.username) {
        this.setState({ notLoggeIn: false });
        //this.props.history.replace('/movies');
      } else {
        this.setState({ showAlert: true, alertTitle: 'Invalid Username/Password' });
      }
    }).catch(err => console.log('err during login', err));
  }

  handleInput = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleFollowOrChat = (username) => {
    const { profileUser } = this.state;
    const loggedIn = localStorage.getItem('username');
    if (profileUser.privacy === 'private') {
      this.setState({ showAlert: true, alertTitle: 'This user has activated a private profile to keep their reviews private.' }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    } else if (!loggedIn) {
      this.setState({ showAlert: true, alertTitle: `Please log in to your MovieCritic account below to perform this action` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
      this.renderLoginForm();
    } else if (username) {
      this.props.followUser(username).then(() => this.fetchUserMovies(username).then(() => this.setState({ showAlert: true, alertTitle: `You are now following ${capitalizeFirstLetter(username)}.` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000))));
    } else {
      this.props.history.push(`/messages`, { profileUser: profileUser.username });
    }
  }

  handleDownload = () => {
    const { profileUser } = this.state;
    const { user } = this.props;
    const loggedIn = localStorage.getItem('username');
    const followingUser = profileUser.followers.includes(user.username);
    if (profileUser.privacy === 'private') {
      this.setState({ showAlert: true, alertTitle: 'This user has activated a private profile to keep their reviews private.' }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    } else if (profileUser.privacy === 'default' && !loggedIn) {
      this.setState({ showAlert: true, alertTitle: `Please log in to your MovieCritic account below to perform this action` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
      this.renderLoginForm();
    } else if (profileUser.privacy === 'default' && !followingUser) {
      this.setState({ showAlert: true, alertTitle: `Please follow this user if you would like to view/download their reviews.` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    }
  }

  render() {
    const { moviesToDownload, moviesToDisplay, showAlert, alertTitle, profileUser } = this.state;
    const { user } = this.props;
    const { username } = this.props.match.params;
    const { avatarUrl } = user.publicProfile;
    const loggedIn = localStorage.getItem('username');
    const showMovieReviews = profileUser.username === loggedIn || profileUser.privacy === 'public' || (profileUser.privacy === 'default' && loggedIn && profileUser.followers.includes(user.username));
    const showPrivateMsg = profileUser.privacy === 'private';
    const showDefaultMsg = profileUser.privacy === 'default' && loggedIn && !profileUser.followers.includes(user.username);
    const showLoginForm = profileUser.privacy === 'default' && !loggedIn;
    return (
      <div className="logo-shift wideFix">
        <SweetAlert
          show={showAlert}
          title={alertTitle}
          onConfirm={() => this.setState({ showAlert: false })}
        />
        <h2 className='App'>Profile Page</h2>
        <br />
        <Link to="#"><h2 className='username'>{username}</h2></Link>
        <Link to="#"> <img className='mb-5' src={avatarUrl} alt="" width="250" height="250" /> </Link>

        <br />
        <div className="row col-sm-12 p-0 profile-buttons">
          <div className="col-sm-6 mb-4">
            {(profileUser.username === loggedIn || profileUser.privacy === 'public' || profileUser.privacy === 'default' && loggedIn && profileUser.followers.includes(user.username)) ? (
              <CsvDownloader filename={`${username}-movies.csv`} datas={moviesToDownload}>
                <button className="profileButtons btn btn-outline-primary">Download Movie/Show Reviews</button>
              </CsvDownloader>
            ) : <button onClick={() => this.handleDownload()} className="profileButtons btn btn-outline-primary">Download Movie/Show Reviews</button>}
          </div>
          <div className="col-sm-6 mb-4">
            <button className="profileButtons btn btn-outline-primary" onClick={() => this.handleFollowOrChat(username)}>Follow {capitalizeFirstLetter(username)}</button>
          </div>
        </div>
        <div className="row col-sm-12 p-0 profile-buttons">
          <div className="col-sm-6 mb-4">
            <button onClick={() => this.handleFollowOrChat()} className="profileButtons btn btn-outline-primary">Message {capitalizeFirstLetter(profileUser.username)}</button>
          </div>
          <div className="col-sm-6 mb-4">
            <a href='/register'><button className="profileButtons btn btn-outline-primary">Create A Free MovieCritic Account</button></a>
          </div>
        </div>

        <br />
        <br />
        <br />
        <h1><a href={`/user/${username}`} className='username'>{username}</a>'s Recent Movie/Show Reviews:</h1>
        <br />
        <br />
        <br />
        {showMovieReviews && (
          <div>
            <div>
              {<MovieList showRating showRating2 movies={moviesToDisplay} history={this.props.history} />}
            </div>
          </div>
        )}
        {!showMovieReviews && showPrivateMsg && <h2>This user has activated a private profile to keep their reviews private.</h2>}
        {!showMovieReviews && !showPrivateMsg && showLoginForm && this.renderLoginForm()}
        {!showMovieReviews && !showPrivateMsg && !showLoginForm && showDefaultMsg && <h2>Please follow this user if you would like to view/download their reviews.</h2>}

        
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    followUser: (userEmail) => dispatch(followUser(userEmail)),
    loginUser: (body) => dispatch(loginUser(body)),
    getUserInfo: (username) => dispatch(getUserInfo(username)),
    getUserMovies: (username) => dispatch(getUserMovies(username))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);