import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getUserMovies, updateMovie } from '../../actions/movieActions';
import MovieForm from './MovieForm';
import MovieList from './MovieList';
import { capitalizeFirstLetter } from '../../utils';

const TYPE_MAP = {
  show: 'series',
  movie: 'movie',
  series: 'show'
}

class ToWatchList extends Component {
  state = {
    rating: "",
    type: "",
    id: null,
    showMovieModal: false,
    mode: 'Add',
    title: null,
    searchInput: '',
    foundMovies: [],
    titleDisabled: false
  };

  componentWillMount() {
    if (!localStorage.getItem('username')) {
      this.props.history.replace('/');
    }
  }

  componentDidMount() {
    if (localStorage.getItem('username')) {
      this.props.getUserMovies(localStorage.getItem('username'));
    }
  }

  handleInputChange = (newState) => {
    this.setState(newState)
  }

  handleSubmit = e => {
    e.preventDefault();
    const { title, rating, type, id } = this.state;
    this.props.updateMovie({ title, rating, type, id, watched: true }).then(() => this.setState({ showMovieModal: false }));
  }

  handleSearchMovie = query => {
    if (query) {
      this.setState({ searchInput: query });
      this.props.searchMovie(query).then(res => {
        this.setState({
          foundMovies: res.map(m => {
            const type = TYPE_MAP[m.Type.toLowerCase()]
            return {
              label: `${m.Title} - ${m.Year} (${capitalizeFirstLetter(type)})`,
              value: m.Title,
              type
            }
          })
        });
      }).catch(err => {
        this.setState({ foundMovies: [] })
      });
    } else {
      this.setState({ searchInput: '', foundMovies: [] });
    }
  }

  handleTitleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ title: selectedOption, type: selectedOption.type });
    } else {
      this.setState({ title: null, type: '' });
    }
  }

  render() {
    const { title, type, id, rating, showMovieModal, mode, searchInput, foundMovies, titleDisabled } = this.state;
    const { movies } = this.props;
    return (
      <div>
        <h2 className="App">My ToWatch List</h2>
        <br />
        <MovieForm movieId={id} handleInputChange={this.handleInputChange} titleDisabled={titleDisabled} title={title} type={type} mode={mode} showType />
        <MovieList showDeleteMovie showRating releaseDate rateMovie2 movies={movies} history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    movies: (store.movie.movies || []).filter(m => !m.watched).sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMovies: (username) => dispatch(getUserMovies(username)),
    updateMovie: movieInfo => dispatch(updateMovie(movieInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToWatchList);
