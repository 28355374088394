export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAILURE = 'GET_PUBLIC_PROFILE_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_FOLLOWER_SUCCESS = 'DELETE_FOLLOWER_SUCCESS';
export const DELETE_FOLLOWER_FAILURE = 'DELETE_FOLLOWER_FAILURE';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

export const ADD_MOVIE_SUCCESS = 'ADD_MOVIE_SUCCESS';
export const ADD_MOVIE_FAILURE = 'ADD_MOVIE_FAILURE';
export const ADD_MOVIE_COMMENT_SUCCESS = 'ADD_MOVIE_COMMENT_SUCCESS';
export const ADD_MOVIE_COMMENT_FAILURE = 'ADD_MOVIE_COMMENT_FAILURE';
export const GET_USER_MOVIES_SUCCESS = 'GET_USER_MOVIES_SUCCESS';
export const GET_USER_MOVIES_FAILURE = 'GET_USER_MOVIES_FAILURE';
export const GET_ONLINE_MOVIES_SUCCESS = 'GET_ONLINE_MOVIES_SUCCESS';
export const GET_ONLINE_MOVIES_FAILURE = 'GET_ONLINE_MOVIES_FAILURE';
export const GET_FOLLOWING_MOVIES_SUCCESS = 'GET_FOLLOWING_MOVIES_SUCCESS';
export const GET_FOLLOWING_MOVIES_FAILURE = 'GET_FOLLOWING_MOVIES_FAILURE';
export const UPDATE_MOVIE_SUCCESS = 'UPDATE_MOVIE_SUCCESS';
export const UPDATE_MOVIE_FAILURE = 'UPDATE_MOVIE_FAILURE';
export const DELETE_MOVIE_SUCCESS = 'DELETE_MOVIE_SUCCESS';
export const DELETE_MOVIE_FAILURE = 'DELETE_MOVIE_FAILURE';
export const IMPORT_MOVIES_SUCCESS = 'IMPORT_MOVIES_SUCCESS';
export const IMPORT_MOVIES_FAILURE = 'IMPORT_MOVIES_FAILURE';
export const RESET_MOVIES_ADDED_FLAGS = 'RESET MOVIES ADDED FLAGS';

export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
export const MARK_USER_NOTIFICATIONS_SUCCESS = 'MARK_USER_NOTIFICATIONS_SUCCESS';
export const MARK_USER_NOTIFICATIONS_FAILURE = 'MARK_USER_NOTIFICATIONS_FAILURE';