import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { getUserNotifications, markNotificationsAsSeen } from '../../actions/notificationActions';

class Notifications extends Component {
  componentDidMount() {
    if (localStorage.getItem('username')) {
      this.props.markNotificationsAsSeen().then(() => this.props.getUserNotifications());
    }
  }

  componentWillMount() {
    if (!localStorage.getItem('username')) {
      this.props.history.replace('/');
    }
  }

  render() {
    const { notifications } = this.props;
    return (
      <div className="Notifications logo-shift">
        <h2 className="App mb-5">Notifications</h2>
        <ul>
          {notifications.reverse().map((n, i) => <li key={i}> <Link to={`/user/${n.content.split(' ')[0]}`}>{n.content.split(' ')[0]}</Link> {n.content.split(' ').slice(1).join(' ')}. - <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
            {new Date(n.createdAt)}
          </Moment> EST</li>)}
        </ul>
        <br />
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    notifications: store.notification.notifications
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserNotifications: notification => dispatch(getUserNotifications(notification)),
    markNotificationsAsSeen: () => dispatch(markNotificationsAsSeen())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);