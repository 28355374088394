import { ADD_NOTIFICATION_SUCCESS, ADD_NOTIFICATION_FAILURE, GET_USER_NOTIFICATIONS_SUCCESS, GET_USER_NOTIFICATIONS_FAILURE } from "../actions/actionTypes";

const initialState = {
    notifications: [],
    error: ''
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION_SUCCESS:
            state.notifications.push(action.payload);
            return JSON.parse(JSON.stringify(state));
        case ADD_NOTIFICATION_FAILURE:
            return JSON.parse(JSON.stringify({...state, error: action.payload}));
        case GET_USER_NOTIFICATIONS_SUCCESS:
            return JSON.parse(JSON.stringify({...state, notifications: action.payload}));
        case GET_USER_NOTIFICATIONS_FAILURE:
            return JSON.parse(JSON.stringify({...state, error: action.payload}));
        default:
            return state;
    }
}

export default notificationReducer
