import React from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from 'rc-pagination';
import Select from "react-select";
import 'rc-pagination/assets/index.css';
import { capitalizeFirstLetter } from '../../utils'
import { getFollowingMovies, rateMovie, getOnlineMovies, addMovie } from '../../actions/movieActions';
import MovieList from './MovieList';
import SweetAlert from 'sweetalert-react';

const MOVIE_TYPE_MAP = {
    tv: 'show',
    movie: 'movie',
    show: 'show'
}

const SOURCE_MAP = {
    online: { label: 'The Movie Database (TMDB)', value: 'online' },
    following: { label: 'Critics You Follow', value: 'following' },
}

const TYPE_MAP = {
    tv: { label: 'Show', value: 'tv' },
    movie: { label: 'Movie', value: 'movie' },
}

const SORT_MAP = {
    release_date: { label: 'Latest', value: 'release_date' },
    vote_average: { label: 'Top Rated', value: 'vote_average' },
    vote_count: { label: 'Most Popular', value: 'vote_count' },
    popularity: { label: 'Trending', value: 'popularity' },
}

const MIN_RATING_MAP = {
    10: { label: 10, value: 10 },
    9.5: { label: 9.5, value: 9.5 },
    9: { label: 9, value: 9 },
    8.5: { label: 8.5, value: 8.5 },
    8: { label: 8, value: 8 },
    7.5: { label: 7.5, value: 7.5 },
    7: { label: 7, value: 7 },
    6.5: { label: 6.5, value: 6.5 },
    6: { label: 6, value: 6 },
    5.5: { label: 5.5, value: 5.5 },
    5: { label: 5, value: 5 },
    4: { label: 4, value: 4 },
    3: { label: 3, value: 3 },
    2: { label: 2, value: 2 },
    1: { label: 1, value: 1 },
    0: { label: 0, value: 0 },
}

const GENRE_MAP = {
    '': { label: 'All', value: '' },
    35: { label: 'Comedy', value: 35 },
    28: { label: 'Action', value: 28 },
    12: { label: 'Adventure', value: 12 },
    80: { label: 'Crime', value: 80 },
    99: { label: 'Documentary', value: 99 },
    18: { label: 'Drama', value: 18 },
    16: { label: 'Animated', value: 16 },
    9648: { label: 'Mystery', value: 9648 },
    878: { label: 'Science Fiction', value: 878 },
    53: { label: 'Thriller', value: 53 },
    27: { label: 'Horro', value: 27 },
    37: { label: 'Western', value: 37 },
}

class Recommendations extends React.Component {
    state = {
        source: 'online',
        type: 'movie',
        period: 'all',
        minRating: 7,
        sort: 'release_date',
        page: 1,
        totalItems: 0,
        showAlert: false,
        msg: '',
        genre: ''
    }

    componentWillMount() {
        if (!localStorage.getItem('username')) {
            this.props.history.replace('/');
        }
    }

    componentDidMount() {
        if (localStorage.getItem('username')) {
            const { type, sort, period, page, minRating, genre } = this.state;
            this.props.getOnlineMovies({ type, sort, period, page, minRating, genre }).then(res => this.setState({ totalItems: res.total_results }));
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.movieError && this.props.movieError !== nextProps.movieError) {
            this.setState({ showAlert: true, msg: nextProps.movieError }, () => setTimeout(() => this.setState({ showAlert: false }), 3000));
        }
        return true;
    }

    handleAddMovie = movie => {
        const { type, source } = this.state;
        if (source === 'online') {
            this.props.addMovie({ ...movie, title: movie.name || movie.original_title, type: MOVIE_TYPE_MAP[type], watched: false }).then(() => {
                this.setState({
                    showAlert: true,
                    msg: 'Movie Added To Your WatchList.'
                }, () => setTimeout(() => this.setState({ showAlert: false }), 1000))
            });
        } else {
            this.props.addMovie({ ...movie, type: MOVIE_TYPE_MAP[type], watched: false }).then(() => {
                this.setState({
                    showAlert: true,
                    msg: 'Movie Added To Your WatchList.'
                }, () => setTimeout(() => this.setState({ showAlert: false }), 1000))
            });
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value }, () => {
            const { type, sort, source, period, page, minRating, genre } = this.state;
            if (source === 'online') {
                this.props.getOnlineMovies({ type, sort, period, page, minRating, genre }).then(res => this.setState({ totalItems: res.total_results }));
            } else {
                this.props.getFollowingMovies({ type, sort, period, page, minRating }).then(movies => {
                    this.setState({ totalItems: movies.length });
                });
            }
        });
    }

    handlePageClick = (page) => {
        const { source, type, sort, period, minRating, genre } = this.state;
        if (source === 'online') {
            this.props.getOnlineMovies({ type, sort, period, page, minRating, genre }).then(res => this.setState({ totalItems: res.total_results, page }, () => window.scrollTo(0, 0)));
        } else {
            this.props.getFollowingMovies({ type, sort, period, page, minRating }).then(movies => this.setState({ totalItems: movies.length, page }, () => window.scrollTo(0, 0)));
        }
    }

    render() {
        const { source, type, totalItems, msg, showAlert, page, minRating, sort, genre } = this.state;
        const { onlineMovies, followingMovies } = this.props;
        return (
            <div>
                <h2 className="App mb-4">Movie/Show Recommendations</h2>

                <div className="container">
                    <div className="row mb-2">
                        <div className="col-md-4 mb-2">
                            <h3>Source</h3>
                            <Select
                                value={SOURCE_MAP[source]}
                                onChange={({ value }) => this.handleChange('source', value)}
                                options={Object.values(SOURCE_MAP)}
                                required
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <h3>Type</h3>
                            <Select
                                value={TYPE_MAP[type]}
                                onChange={({ value }) => this.handleChange('type', value)}
                                options={Object.values(TYPE_MAP)}
                                required
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <h3>Sort By</h3>
                            <Select
                                value={SORT_MAP[sort]}
                                onChange={({ value }) => this.handleChange('sort', value)}
                                options={Object.values(SORT_MAP)}
                                required
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <h3>Minimum Rating</h3>
                            <Select
                                value={MIN_RATING_MAP[minRating]}
                                onChange={({ value }) => this.handleChange('minRating', value)}
                                options={[{ label: 10, value: 10 }, { label: 9.5, value: 9.5 }, { label: 9, value: 9 }, { label: 8.5, value: 8.5 }, { label: 8, value: 8 }, { label: 7.5, value: 7.5 }, { label: 7, value: 7 }, { label: 6.5, value: 6.5 }, { label: 6, value: 6 }, { label: 5.5, value: 5.5 }, { label: 5, value: 5 }, { label: 4, value: 4 }, { label: 3, value: 3 }, { label: 2, value: 2 }, { label: 1, value: 1 }, { label: 0, value: 0 },]}
                                required
                            />
                        </div>

                        {source === 'online' && <div className="col-md-4 mb-2">
                            <h3>Genre</h3>
                            <Select
                                value={GENRE_MAP[genre]}
                                onChange={({ value }) => this.handleChange('genre', value)}
                                options={Object.values(GENRE_MAP)}
                                required
                            />
                        </div>}
                    </div>
                </div>

                <div className="container">
                    {source === 'following' ? <MovieList showVoteCount aveRating showRating releaseDate addToWatchList movies={followingMovies} history={this.props.history} /> :
                        onlineMovies.map((movie, index) => {
                            return (
                                <div key={index} className="movieList row mb-5">
                                    <div className="col-sm-8">
                                        <div>
                                            {capitalizeFirstLetter(movie.title || movie.name)}
                                        </div>
                                        <div>
                                            Average Rating: {movie.vote_average}
                                        </div>
                                        <div>
                                            Number of Votes: {movie.vote_count}
                                        </div>
                                        <div>
                                            Type: {capitalizeFirstLetter(MOVIE_TYPE_MAP[type])}
                                        </div>
                                        <div>
                                            Released on <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
                                                {new Date(movie.release_date || movie.first_air_date)}
                                            </Moment> EST
                                        </div>

                                        <button className="socialButton btn btn-lg btn-outline-primary" onClick={() => this.handleAddMovie(movie)}>Add To WatchList</button>
                                    </div>
                                    <div className="moviePoster col-sm-4">
                                        {<img src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} alt="" height="250" width="250" />}
                                    </div>
                                    <hr />
                                </div>)
                        })}
                </div>
                <SweetAlert
                    show={showAlert}
                    title={msg}
                    onConfirm={() => {
                        this.setState({ showAlert: false });
                    }}
                />
                <div className="row col-sm-12">
                    <div className="offset-sm-4">
                        <Pagination pageSize={20} onChange={this.handlePageClick} current={page} total={totalItems} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
        followingMovies: store.movie.followingMovies.filter(m => m.watched),
        onlineMovies: store.movie.onlineMovies,
        movieError: store.movie.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFollowingMovies: (params) => dispatch(getFollowingMovies(params)),
        addMovie: movie => dispatch(addMovie(movie)),
        rateMovie: movie => dispatch(rateMovie(movie)),
        getOnlineMovies: (params) => dispatch(getOnlineMovies(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
