import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import SweetAlert from 'sweetalert-react';
import Select from 'react-select';
import socketIo from 'socket.io-client';
import moment from 'moment-timezone';
import { getAllUsers } from '../actions/userActions';
import { capitalizeFirstLetter } from '../utils';

class Messages extends Component {
    state = {
        showModal: false,
        userToChat: null,
        users: [],
        usersCopy: [],
        msgBody: '',
        recipients: [],
        selectedRecipientMessages: [],
        showWarning: false,
        recipient: ''
    }

    componentDidMount() {
        this.username = localStorage.getItem('username');
        if (!this.username) {
            return this.props.history.replace('/');
        }

        this.props.getAllUsers().then(users => {
            users = users && users.length ? users.map(u => ({ label: u.username, value: u.username })) : [];
            this.setState({ usersCopy: users });
        });

        this.socket = socketIo({
            query: {
                username: this.username
            }
        });

        this.socket.emit('getRecipients');

        this.socket.on('recipients', recipients => {
            let recipient = this.props.location.state && this.props.location.state.profileUser;
            if (recipient) {
                if (recipients.find(r => r.name === recipient)) {
                    this.setState({ recipients }, () => {
                        this.readMessages(recipient);
                    });
                } else {
                    this.setState({ showModal: true, recipient, userToChat: { label: recipient, value: recipient } });
                }
            } else {
                this.setState({ recipients });
            }
        });

        this.socket.on('existingMsgs', selectedRecipientMessages => {
            const { recipients, recipient } = this.state;
            const currentRecipient = recipients.find(r => r.name === recipient);
            if (currentRecipient) {
                currentRecipient.unread = 0;
            }
            this.setState({ selectedRecipientMessages, recipients });
        });

        this.socket.on('newRes', sender => {
            const { recipients } = this.state;
            const currentRecipient = recipients.find(r => r.name === sender);
            if (currentRecipient) {
                currentRecipient.unread += 1;
            }
            this.setState({ recipients });
        });

        this.socket.on('newRoom', ({room}) => {
            const roomUsers = room.split('|');
            if (roomUsers.includes(this.username)) {
                const recipient = roomUsers.find(u => u !== this.username);
                this.socket.emit('joinNewRoom', {room}, () => {
                    this.setState(prevState => ({
                        recipients: prevState.recipients.concat({name: recipient, unread: 0})
                    }))
                });
            }
        })
    }

    handleSearchUsers = query => {
        const { usersCopy } = this.state;
        const users = query.length < 3
            ? []
            : usersCopy.filter(({ value }) => value.toLowerCase().includes(query.toLowerCase())).slice(0, 3);

        this.setState({
            users
        });
    }

    handleUserSelect = selectedOption => {
        if (selectedOption) {
            this.setState({ userToChat: selectedOption, recipient: selectedOption.value });
        } else {
            this.setState({ userToChat: null });
        }
    }

    sendFirstMessage = e => {
        e.preventDefault();
        const { recipient, msgBody, recipients } = this.state;
        if (recipient) {
            const room = [this.username, recipient].sort().join('|');
            this.socket.emit('chatNewUser', { room }, () => {
                this.socket.emit('newMsg', { sender: this.username, recipient, body: msgBody }, createdMsg => {
                    const newState = { showModal: false, msgBody: '', userToChat: null };
                    if (!recipients.find(r => r.name === recipient)) {
                        newState['recipients'] = recipients.concat({ name: recipient, unread: 0 });
                    }
                    this.setState(newState, () => {
                        this.readMessages(recipient);
                    });
                });
            });
        }
    }

    sendMessage = e => {
        e.preventDefault();
        const loggedInUser = localStorage.getItem('username');
        const { recipient, msgBody } = this.state;
        this.socket.emit('newMsg', { sender: loggedInUser, recipient, body: msgBody }, createdMsg => {
            this.setState(prevState => ({ selectedRecipientMessages: prevState.selectedRecipientMessages.concat(createdMsg), msgBody: '' }));
        });
    }

    readMessages = recipient => {
        this.setState({ recipient, selectedRecipientMessages: [] }, () => {
            const room = [this.username, recipient].sort().join('|');
            this.socket.emit('recipientMsgs', { room });
        });
    }

    deleteMessages = recipient => {
        const room = [this.username, recipient].sort().join('|');
        this.socket.emit('deleteUserChats', room, () => {
            this.setState(prevState => ({ recipients: prevState.recipients.filter(r => r.name !== recipient), selectedRecipientMessages: [], recipient: '' }));
        });
    }

    render() {
        const { showModal, userToChat, users, msgBody, recipients, selectedRecipientMessages, recipient, showWarning } = this.state;
        const recipientList = recipients.map((r, i) => {
            return (<div>
                <SweetAlert
                    show={showWarning}
                    title='Warning'
                    text='You are about to delete a message thread.  Would you like to proceed?'
                    showCancelButton
                    onConfirm={() => {
                        this.setState({ showWarning: false }, () => {
                            this.deleteMessages(r.name);
                        })
                    }}
                    onCancel={() => {
                        this.setState({ showWarning: false })
                    }}
                />
                <div onClick={() => this.readMessages(r.name)} key={i} className="row align-items-center">
                    <i onClick={() => this.setState({ showWarning: true})} className="fas fa-trash-alt mr-3"></i>
                    <h3 className="mb-0">
                        {capitalizeFirstLetter(r.name)}
                        {r.unread ? ` - ${r.unread} New` : ''}
                    </h3>
                </div>
                </div>
                
            )
        });
        const selectedRecipientMessageList = selectedRecipientMessages.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map(
            (m, i) => (
                <h4 key={i}>
                    <a href={`/user/${m.sender}`}>
                        {capitalizeFirstLetter(m.sender)}
                    </a> - &nbsp;
                    <span>
                        {moment(m.createdAt).tz('America/New_York').format('MMMM Do YYYY, h:mm a')}:
                    </span>
                    <span className='float-right'>
                        {m.body}
                    </span>
                </h4>
            )
        );
        const customStyles = {
            content: {
                top: '0%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                width: '100%',
                height: '100%'
            }
        };
        return (
            <div className="container">
                <h2 className="App">Messages</h2>
                <br />
                <div className="row messages-container flex-column flex-sm-row">
                    <Modal
                        isOpen={showModal}
                        style={customStyles}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={() => this.setState({ showModal: false })}
                    >
                        <br />
                        <h3 className='App'>New Message</h3>
                        <br />
                        <form onSubmit={this.sendFirstMessage}>
                            <Select
                                value={userToChat}
                                onChange={this.handleUserSelect}
                                onInputChange={this.handleSearchUsers}
                                placeholder='Enter Username...'
                                noOptionsMessage={() => null}
                                isClearable={true}
                                options={users}
                                autoFocus="true"
                                required
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className='input-group'>
                                <input className='messageModalInput form-control' placeholder="Enter your message..." required value={msgBody} onChange={e => this.setState({ msgBody: e.target.value })} />
                                <button className="btn btn-outline-primary input-group-append ml-2">Send</button>
                            </div>
                        </form>
                        <br />
                        <button onClick={() => this.setState({ showModal: false })} className="btn btn-outline-primary">Close</button>
                    </Modal>
                    <div className="recipients-list col-12 col-sm-5 col-md-4">
                        <div className="row">
                            <button className="btn btn-outline-primary btn-lg btn-block" onClick={() => this.setState({ showModal: true, selectedRecipientMessages: [] })}>New Message</button>
                        </div>
                        {recipientList}
                    </div>
                    <div className="col-12 col-sm-7 col-md-8 message-content">
                        <a href={`/user/${recipient}`}> <h2>{capitalizeFirstLetter(recipient)}</h2></a>
                        {selectedRecipientMessageList}
                        {selectedRecipientMessages.length ? (
                            <form onSubmit={this.sendMessage}>
                                <input
                                    placeholder="Enter your message..."
                                    autoFocus="true"
                                    required value={msgBody} onChange={e => this.setState({ msgBody: e.target.value })} />
                                <button className="btn btn-outline-primary">Send</button>
                            </form>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
        users: store.user.all
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: () => dispatch(getAllUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);