import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { verifyPasswordReset } from '../actions/passwordResetActions';
import { updateUser } from '../actions/userActions';

class PasswordReset extends Component {

    state = {
        password: '',
        passwordConfirm: '',
        showAlert: false,
        alertMsg: ''
    }    

    componentDidMount() {
        localStorage.setItem('token', this.props.match.params.resetToken)
        this.props.verifyPasswordReset(this.props.match.params.resetToken).then(res => {
            if (res.success) {
                this.setState({ validUser: true })
            } else {
                this.props.history.replace('/');
            }
        }).catch(err => console.log('err verifying user', err));
    }

    handleInputChange = (prop, value) => {
        this.setState({ [prop]: value })
    }

    handleResetPassword = e => {
        e.preventDefault();
        const { password, passwordConfirm } = this.state;
        if (password === passwordConfirm) {
            this.props.updateUser({ username: this.props.match.params.username, password: this.state.password }).then(() => {
                this.setState({ showAlert: true, alertMsg: 'Password reset successful' }, () => setTimeout(() => {
                    this.setState({ showAlert: false }, () => this.props.history.replace('/'));
                }, 2000));
            });
        } else {
            this.setState({ showAlert: true, alertMsg: 'Password does not match' });
        }
    }

    render() {
        const { showAlert, alertMsg, password, passwordConfirm } = this.state;
        return (
            <div className="">
                <SweetAlert
                    show={showAlert}
                    title={alertMsg}
                    onConfirm={() => {
                        this.setState({ showAlert: false }, () => password === passwordConfirm && this.props.history.replace('/'));
                    }}
                />
                <form className="text-center" onSubmit={this.handleResetPassword}>
                <h1>Finish Password Reset</h1>
                <br/>
                <h1>Please Enter Your New Password Below</h1>
                <br/>
                    <div>
                        <label htmlFor="password">New Password</label>
                        <input name="password" required className="form-control" value={password} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="passwordConfirm">New Password Confirmation</label>
                        <input name="passwordConfirm" required className="form-control" value={passwordConfirm} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                    </div>
                    <button className="btn btn-outline-primary mt-2">Submit</button>
                </form>
                <br/>
                <h4 className='Guide'>
                        Please enter your new password and press "Submit".  If you entered your new password correctly,
                        your password will be updated to the one you just submitted and you will be redirected to the
                        login page within a few seconds.  If you have any issues, please contact info@moviecriticapp.com 
                        for additional assistance.  Thanks.
                    </h4>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyPasswordReset: (resetToken) => dispatch(verifyPasswordReset(resetToken)),
        updateUser: (userInfo) => dispatch(updateUser(userInfo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
