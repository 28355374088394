import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(reduxThunk)
    ));

export default store;


// Component where request is made => action => reducer => store => back to the original componeent with the new data