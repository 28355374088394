import React from 'react';
import Bowser from "bowser";
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../node_modules/sweetalert/dist/sweetalert.css'
import { loginUser, createUser } from '../actions/userActions'

const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const osName = browser.getOSName();
 const browserIsSupported = browserName === 'Chrome' && osName !== 'iOS'; 

class Login extends React.Component {
  state = {
    password: '',
    username: '',
    showAlert: false,
    alertMsg: '',
    email: '',
    recognitionStarted: false
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }

   componentDidMount() {
    if (browserIsSupported) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
      const grammar = '#JSGF V1.0;'
      this.recognition = new SpeechRecognition();
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      this.recognition.grammars = speechRecognitionList;
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = false;

      this.recognition.onresult = event => {
        const last = event.results.length - 1;
        const command = event.results[last][0].transcript.replace(/\s/g, '').toLowerCase();
        this.handleInputChange('username', command);
      };

      this.recognition.onspeechend = () => {
        this.setState({ showAlert: false });
        browserIsSupported && this.recognition && this.recognition.stop();
      };

      this.recognition.onstart = () => {
        this.setState({ recognitionStarted: true });
      }
    }
  } 

  handleVoiceRecognition = () => {
    const { recognitionStarted } = this.state;
    this.setState({ showAlert: true, alertMsg: 'Listening...' }, () => {
      !recognitionStarted && setTimeout(() => this.recognition.start(), 100);
    });
  }

  registerUser = (e) => {
    e.preventDefault();
    const { password, username, email } = this.state;
    const body = { password, username, email };

    this.props.createUser(body).then(() => {
      this.setState({
        showAlert: true,
        alertMsg: `Welcome to MovieCritic! You have successfully registered and are now following a demo account.`
      }, () => setTimeout(() => {
        this.setState({ showAlert: false }, () => this.props.history.replace('/home'));
      }, 2000));
    }).catch(alertMsg => {
      this.setState({
        showAlert: true,
        alertMsg
      }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    });
  }

  handleInputChange = (prop, value) => {
    this.setState({ [prop]: value })
  }

  render() {
    const { showAlert, alertMsg, username, email, password } = this.state;
    return (
      <div className="logo-shift wideFix">
        <h1>Register</h1>
        <br />
        <h4>Welcome to MovieCritic, a cross-platform social network to share and discover movie/show reviews.</h4>
        <hr/>
        <SweetAlert
          show={showAlert}
          title={alertMsg}
          onConfirm={() => {
            browserIsSupported && this.recognition && this.recognition.stop();
            this.setState({ showAlert: false, recognitionStarted: false });
          }}
          onOutsideClick={() => {
            browserIsSupported && this.recognition && this.recognition.stop();
            this.setState({ showAlert: false, recognitionStarted: false });
          }}
        />
        <form onSubmit={this.registerUser}>
          <label htmlFor="username">Username</label>
          <div className="input-group mb-3">
            <input autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={16} type="text" className="form-control" name="username" aria-describedby="usernameHelp" placeholder="Choose A Username" value={username} onChange={e => this.handleInputChange(e.target.name, e.target.value)} required />
            { browserIsSupported && <span className="input-group-addon ml-2">
              <i onClick={() => this.handleVoiceRecognition()} className="fas fa-2x fa-microphone"></i>
        </span> }
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input autoComplete="off" autoCorrect="off" autoCapitalize="none" type="email" className="form-control" name="email" placeholder="Enter your Email Address" value={email} onChange={e => this.handleInputChange(e.target.name, e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input autoComplete="off" autoCorrect="off" autoCapitalize="none" type="password" className="form-control" name="password" placeholder="Choose A Password" value={password} onChange={e => this.handleInputChange(e.target.name, e.target.value)} required />
          </div>
          <button type="submit" className="btn btn-outline-primary">Register</button>
        </form>
        <div>
          <br/>
          <h4>Already have an account? <Link to="/">Login</Link></h4><br/>
          <h4>Test the app without registering by entering "demo" as username and password.</h4><br/>
        </div>
      <br/>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user
  }
}

const mapDispatchToProps = (dispach) => {
  return {
    createUser: (body) => dispach(createUser(body)),
    loginUser: (body) => dispach(loginUser(body))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
