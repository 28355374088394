import React, { Component } from "react";
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import Select from 'react-select';
import Bowser from "bowser";
import Modal from 'react-modal';
import { addMovie, updateMovie, searchMovie } from "../../actions/movieActions";
import { capitalizeFirstLetter } from '../../utils';

const TYPE_MAP = {
    show: 'series',
    movie: 'movie',
    series: 'show'
}

const ratingOptions = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4, 3, 2, 1, 0].map(r => ({ label: r === 0 ? 'Bomb' : r, value: r }));
const movieTypeOptions = ['movie', 'show'].map(r => ({ label: capitalizeFirstLetter(r), value: r }));
const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const osName = browser.getOSName();
const browserIsSupported = browserName === 'Chrome' && osName !== 'iOS';

class MovieForm extends Component {
    state = {
        alertMsg: '',
        showAlert: false,
        showModal: false,
        modalContent: '',
        modalTitle: '',
        recognitionStarted: false,
        searchInput: '',
        foundMovies: [],
        ratingInput: '',
        movieTypeInput: ''
    };

    componentDidMount() {
        if (browserIsSupported) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
            const grammar = '#JSGF V1.0;'
            this.recognition = new SpeechRecognition();
            const speechRecognitionList = new SpeechGrammarList();
            speechRecognitionList.addFromString(grammar, 1);
            this.recognition.grammars = speechRecognitionList;
            this.recognition.lang = 'en-US';
            this.recognition.interimResults = false;

            this.recognition.onresult = event => {
                const last = event.results.length - 1;
                const command = event.results[last][0].transcript.toLowerCase();
                this.handleSearchMovie(command);
                document.querySelector('#search-input-select input').focus();
            };

            this.recognition.onspeechend = () => {
                this.setState({ showAlert: false });
                { browserIsSupported && this.recognition.stop(); }
            };

            this.recognition.onstart = () => {
                this.setState({ recognitionStarted: true });
            }
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const { rating, title, type, movieId, movie, showRating, mode, whereToShare, editMovie, handleInputChange } = this.props;
        if (title) {
            const body = { title: title.value, type: type.value, whereToShare };
            if (showRating) {
                if (rating) body.rating = rating.value;
                body.watched = true;
            } else {
                body.watched = false;
            }
            if (mode === 'Add') {
                this.props.addMovie(body).then(res => {
                    if (res.err) {
                        this.setState({
                            showAlert: true,
                            alertMsg: res.err
                        }, () => {
                            setTimeout(() => this.setState({ showAlert: false }), 1000);
                            handleInputChange({ title: null, type: null, rating: null })
                        });
                    } else {
                        this.setState({
                            showAlert: true,
                            alertMsg: 'Movie/Show successfully added!'
                        }, () => {
                            setTimeout(() => this.setState({ showAlert: false }), 1000);
                            handleInputChange({ title: null, type: null, rating: null })
                        });
                    }
                });
            } else {
                body.id = movieId;
                if (movie) body.userId = movie.userId;
                this.props.updateMovie(body).then(res => {
                    if (res.title) {
                        this.setState({ showAlert: true, alertMsg: 'Movie/Show successfully updated' }, () => {
                            editMovie();
                            setTimeout(() => this.setState({ showAlert: false }), 1000);
                        });
                    }
                });
            }
        } else {
            this.setState({showAlert: true, alertMsg: 'All fields are required to add movie/show.'}, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
        }
    };

    handleVoiceRecognition = () => {
        const { recognitionStarted } = this.state;
        this.setState({ showAlert: true, alertMsg: 'Listening...' }, () => {
            !recognitionStarted && setTimeout(() => this.recognition.start(), 100);
        });
    }

    handleSearchMovie = query => {
        if (query) {
            this.setState({ searchInput: query });
            this.props.searchMovie(query).then(res => {
                this.setState({
                    foundMovies: res.map(m => {
                        const type = TYPE_MAP[m.Type.toLowerCase()]
                        return {
                            label: `${m.Title} - ${m.Year} (${capitalizeFirstLetter(type)})`,
                            value: m.Title,
                            type
                        }
                    }).slice(0, 5)
                });
            }).catch(err => {
                this.setState({ foundMovies: [] })
            });
        } else {
            this.setState({ searchInput: '', foundMovies: [] });
        }
    }

    handleRatingInput = query => {
        if (ratingOptions.map(r => r.value).includes(parseInt(query))) {
            this.setState({ ratingInput: query })
        } else {
            this.setState({ ratingInput: '' })
        }
    }

    handleMovieTypeInput = query => {
        if (movieTypeOptions.map(r => r.value).includes(query.toLowerCase())) {
            this.setState({ movieTypeInput: query })
        } else {
            this.setState({ movieTypeInput: '' })
        }
    }   

    handleTitleChange = (selectedOption) => {
        if (selectedOption) {
            this.props.handleInputChange({ title: selectedOption, type: {label: capitalizeFirstLetter(selectedOption.type), value: selectedOption.type} });
        } else {
            this.props.handleInputChange({ title: null, type: null });
        }
    }

    handleReactSelectInputChange = (input, selectedOption) => {
        if (selectedOption) {
            this.props.handleInputChange({ [input]: selectedOption });
        } else {
            this.props.handleInputChange({ [input]: null });
        }
    }

    render() {
        const { title, rating, type, showRating, mode, showType, showShareButton, titleDisabled } = this.props;
        const { showAlert, alertMsg, showModal, modalContent, searchInput, foundMovies, ratingInput, movieTypeInput } = this.state;
        const customStyles = {
            content: {
                top: '0%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                width: '100%',
                height: '100%'
            }
        };
        return (
            <div className="App container">
                <SweetAlert
                    show={showAlert}
                    title={alertMsg}
                    onConfirm={() => {
                        browserIsSupported && this.recognition && this.recognition.stop();
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                    onOutsideClick={() => {
                        browserIsSupported && this.recognition && this.recognition.stop();
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                />
                <Modal
                    isOpen={showShareButton && showModal}
                    style={customStyles}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showModal: false })}
                >
                    {modalContent}
                </Modal>
                <form onSubmit={this.handleSubmit}>
                    {/* <label>Title</label> */}
                    <div className="row">
                        <div className="col-12 input-group">
                            <Select
                                id="search-input-select"
                                className="reactSelect react-select-lg blue-text"
                                value={title}
                                onChange={this.handleTitleChange}
                                inputValue={searchInput}
                                onInputChange={this.handleSearchMovie}
                                placeholder='Enter Title...'
                                noOptionsMessage={() => null}
                                isClearable={true}
                                isDisabled={titleDisabled}
                                menuIsOpen={true}
                                options={foundMovies}
                                autoFocus="true"
                            />
                            {browserIsSupported && mode === 'Add' && <span className="input-group-addon">
                                <i onClick={() => this.handleVoiceRecognition()} className="fas fa-2x fa-microphone"></i>
                            </span>}
                        </div>
                    </div>
                    {showRating && <div className="form-group mb-0">
                        {/* <label>Rating</label> */}
                        <Select
                            id="search-input-select"
                            className="reactSelect react-select-lg blue-text"
                            value={rating}
                            onChange={o => this.handleReactSelectInputChange('rating', o)}
                            inputValue={ratingInput}
                            onInputChange={this.handleRatingInput}
                            placeholder='Enter Rating...'
                            noOptionsMessage={() => null}
                            isClearable={true}
                            options={ratingOptions}
                        />
                    </div>}
                    {showType && <div className="form-group mb-4">
                        {/* <label>Type</label> */}
                        <Select
                            id="search-input-select"
                            className="reactSelect react-select-lg blue-text"
                            value={type}
                            onChange={o => this.handleReactSelectInputChange('type', o)}
                            inputValue={movieTypeInput}
                            onInputChange={this.handleMovieTypeInput}
                            placeholder='Select Type...'
                            noOptionsMessage={() => null}
                            isClearable={true}
                            options={movieTypeOptions}
                        />
                    </div>}
                    <button className="btn btn-outline-primary btn-lg mt-3" >{`${mode} Movie/Show`}</button>
                </form>
                <hr />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
        movieError: store.movie.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addMovie: (body) => dispatch(addMovie(body)),
        updateMovie: (body) => dispatch(updateMovie(body)),
        searchMovie: query => dispatch(searchMovie(query))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieForm)
