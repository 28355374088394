import {combineReducers} from 'redux';
import userReducer from './userReducer';
import movieReducer from './movieReducer';
import notificationReducer from './notificationReducer';

const allReducers = combineReducers({
    notification: notificationReducer,
    movie: movieReducer,
    user: userReducer
});

export default allReducers;