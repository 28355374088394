import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Bowser from "bowser";
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../actions/passwordResetActions';

const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const osName = browser.getOSName();
/* const browserIsSupported = browserName === 'Chrome' && osName !== 'iOS'; */

class Login extends React.Component {
    state = {
        usernameOrEmail: '',
        password: '',
        showAlert: false,
        alertMsg: '',
        alertTitle: '',
        showModal: false,
        showCancelButton: false,
        resetEmail: '',
        resetUsername: '',
        recognitionStarted: false
    }

    componentWillMount() {
        if (localStorage.getItem('token') && localStorage.getItem('username')) {
            this.props.history.replace('/home');
        }
        window.scrollTo(0, 0);
    }

    /*  componentDidMount() {
       if (browserIsSupported) {
         const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
         const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
         const grammar = '#JSGF V1.0;'
         this.recognition = new SpeechRecognition();
         const speechRecognitionList = new SpeechGrammarList();
         speechRecognitionList.addFromString(grammar, 1);
         this.recognition.grammars = speechRecognitionList;
         this.recognition.lang = 'en-US';
         this.recognition.interimResults = false;
   
         this.recognition.onresult = event => {
           const last = event.results.length - 1;
           const command = event.results[last][0].transcript.replace(/\s/g, '').toLowerCase();
           this.handleInputChange('usernameOrEmail', command);
         };
   
         this.recognition.onspeechend = () => {
           this.setState({ showAlert: false });
           {  browserIsSupported && this.recognition.stop();}
         };
   
         this.recognition.onstart = () => {
           this.setState({ recognitionStarted: true });
         }
       }
     }
    */

    handleVoiceRecognition = () => {
        const { recognitionStarted } = this.state;
        this.setState({ showAlert: true, alertMsg: 'Listening...' }, () => {
            !recognitionStarted && setTimeout(() => this.recognition.start(), 100);
        });
    }

    handleInputChange = (prop, value) => {
        this.setState({ [prop]: value })
    }

    sendResetEmail = e => {
        e.preventDefault();
        const { resetEmail, resetUsername } = this.state;
        this.props.requestPasswordReset({ email: resetEmail, username: resetUsername }).then(() => {
            this.setState({
                showAlert: true,
                alertTitle: '',
                alertMsg: 'Password reset submitted. An email with reset link should be sent to the email on file for the username. If your email is not received in 10 minutes, contact info@moviecriticapp.com. Thanks.',
                showCancelButton: false,
                showModal: false
            }, () => setTimeout(() => this.setState({ showAlert: false }), 3000));
        });
    }

    render() {
        const { showAlert, alertMsg, resetEmail, resetUsername, showCancelButton, alertTitle, recognitionStarted } = this.state;
        return (
            <div className="logo-shift">
                <form className="text-center" onSubmit={this.sendResetEmail}>
                    <h1>Begin Password Reset</h1>
                    <br/>
                    <h3>Please Enter Your Username and Email Address</h3>
                    <br/>
                    <div>
                        <label htmlFor="resetUsername">Username</label>
                        <input required name="resetUsername" placeholder="Enter Username" className="form-control" value={resetUsername} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="resetEmail">Email</label>
                        <input required name="resetEmail" placeholder="Enter Email" className="form-control" value={resetEmail} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                    </div>
                    <button className="btn btn-outline-primary mt-2">Submit</button>
                </form>
                <br/>
                    <h4 className='Guide'>
                        If the username and email address you submit matches what we have on file, an email will be sent to you 
                        within minutes with a link to reset your password.  If you submit your username and email and do not 
                        recieve an email within 10 minutes, please contact info@moviecriticapp.com for further assistance. Thanks.
                    </h4>
                <br/>
                <SweetAlert
                    show={showAlert}
                    title={alertTitle}
                    html
                    text={renderToStaticMarkup(<h3>{alertMsg}</h3>)}
                    showCancelButton={showCancelButton}
                    onConfirm={() => {
                        this.setState({ showAlert: false, recognitionStarted: false, showModal: recognitionStarted ? false : (resetEmail ? false : true) });
                    }}
                    onCancel={() => {
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                    onOutsideClick={() => {
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPasswordReset: (userInfo) => dispatch(requestPasswordReset(userInfo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
