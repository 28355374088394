import React, { Component } from 'react';

export default class Feedback extends Component {

  render() {
    return (
      <div className="Privacy logo-shift">
        <div className="Feedback">
          <h2 className="App mb-5">Feedback/Privacy</h2>
          <br />
          <h3 className='App'>Feedback</h3>
          <br />
          <h4>
            If you would like to provide feedback, report a bug, or make a suggestion on how <br />
            to improve the app, please email info(at)moviecriticapp.com. Thanks!
            </h4>
          <br />
          <h3 className='App'>Privacy Policy</h3>
          <br />
          <h4>
            Protecting your private information is our priority. This Statement of Privacy applies
            to www.moviecriticapp.com, along with the iphone and android mobile applications
            of MovieCritic, and governs data collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to MovieCritic include www.moviecriticapp.com. The
            MovieCritic website is an entertainment site. By using the MovieCritic website or mobile
            MovieCritic, you consent to the data practices described in this statement.
            <br />
            <br />
            Collection of your Personal Information <br />
            We do not collect any personal information about you unless you voluntarily provide it to us.
            However, you may be required to provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may include: (a) registering for an account
            on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d) sending us an email message; (e)
            submitting your credit card or other payment information when ordering and purchasing products and
            services on our Site. To wit, we will use your information for, but not limited to, communicating
            with you in relation to services and/or products you have requested from us. We also may gather
            additional personal or non-personal information in the future.
            <br />
            <br />
            Sharing Information with Third Parties <br />
            MovieCritic does not sell, rent or lease its customer lists to third parties.
            MovieCritic may share data with trusted partners to help perform statistical analysis, send you email
            or postal mail, provide customer support, or arrange for deliveries. All such third parties are
            prohibited from using your personal information except to provide these services to MovieCritic, and
            they are required to maintain the confidentiality of your information.
            MovieCritic may disclose your personal information, without notice, if required to do so by law or in
            the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
            with legal process served on MovieCritic or the site; (b) protect and defend the rights or property of
            MovieCritic; and/or (c) act under exigent circumstances to protect the personal safety of users of
            MovieCritic, or the public.
            <br />
            <br />
            Automatically Collected Information <br />
            Information about your computer hardware and software may be automatically collected by MovieCritic.
            This information can include: your IP address, browser type, domain names, access times and referring
            website addresses. This information is used for the operation of the service, to maintain quality of
            the service, and to provide general statistics regarding use of the MovieCritic website.
            <br />
            <br />
            Security of your Personal Information <br />
            MovieCritic secures your personal information from unauthorized access, use, or disclosure. MovieCritic
            uses the following methods for this purpose: <br />
            -	SSL Protocol <br />
            -	JWT Authentication <br />
            -	Password Encryption <br />
            -	Personal Privacy Settings <br />
            <br />
            When personal information (such as a credit card number) is transmitted to other websites, it is protected
            through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
            We strive to take appropriate security measures to protect against unauthorized access to or alteration of
            your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be
            guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge
            that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data exchanged between you and us through this Site
            cannot be guaranteed.
            <br />
            <br />
            Children Under Thirteen <br />
            MovieCritic does not knowingly collect personally identifiable information from children under the age of thirteen. If
            you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
            <br />
            <br />
            External Data Storage Sites <br />
            We may store your data on servers provided by third party hosting vendors with whom we have contracted.
            <br />
            <br />
            Changes to this Statement <br />
            MovieCritic reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes
            in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing
            a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or
            Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy
            Policy; and (b) agreement to abide and be bound by that Policy.
            <br />
            <br />
            Contact Information <br />
            MovieCritic welcomes your questions or comments regarding this Statement of Privacy. If you believe that MovieCritic has not adhered
            to this Statement, please contact MovieCritic at:
            <br />
            <br />
            Email Address:
            info@moviecriticapp.com
            </h4>
        </div>
        <br />
      </div>
    );
  }
}




