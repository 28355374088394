import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom'
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Bowser from "bowser";
import _ from 'lodash';
import { addNotification } from '../../actions/notificationActions';
import { getFollowingMovies, rateMovie, addMovie, updateMovie, searchMovie } from '../../actions/movieActions';
import { followUser, unfollowUser, deleteFollower, getAllUsers } from '../../actions/userActions';
import MovieList from './MovieList';
import { capitalizeFirstLetter } from '../../utils';

const TYPE_MAP = {
    show: 'series',
    movie: 'movie',
    series: 'show'
}

const ratingOptions = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4, 3, 2, 1, 0].map(r => ({ label: r === 0 ? 'Bomb' : r, value: r }));
const movieTypeOptions = ['movie', 'show'].map(r => ({ label: capitalizeFirstLetter(r), value: r }));
const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const osName = browser.getOSName();
const browserIsSupported = browserName === 'Chrome' && osName !== 'iOS';

class Home extends React.Component {
    state = {
        userToFollow: null,
        alertMsg: '',
        showAlert: false,
        showMovieModal: false,
        rating: null,
        type: null,
        whereToShare: '',
        showModal: false,
        scrollList: [],
        showWarning: false,
        userToDelete: '',
        userToUnfollow: '',
        mode: 'Add',
        movieToUpdate: null,
        recognitionStarted: false,
        title: null,
        searchInput: '',
        foundMovies: [],
        titleDisabled: false,
        modalType: '',
        users: [],
        usersCopy: [],
        ratingInput: '',
        movieTypeInput: ''
    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.replace('/login');
        }
        else if (localStorage.getItem('token')){
            this.props.history.replace('/home');
        }
    }

    componentDidMount() {
        if (browserIsSupported) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
            const grammar = '#JSGF V1.0;'
            this.recognition = new SpeechRecognition();
            const speechRecognitionList = new SpeechGrammarList();
            speechRecognitionList.addFromString(grammar, 1);
            this.recognition.grammars = speechRecognitionList;
            this.recognition.lang = 'en-US';
            this.recognition.interimResults = false;

            this.recognition.onresult = event => {
                const last = event.results.length - 1;
                const command = event.results[last][0].transcript.toLowerCase();
                this.handleSearchMovie(command);
                document.querySelector('#search-input-select input').focus();
            };

            this.recognition.onspeechend = () => {
                this.setState({ showAlert: false });
                browserIsSupported && this.recognition && this.recognition.stop();
            };

            this.recognition.onerror = () => {
                this.setState({ showAlert: false });
            };

            this.recognition.onstart = () => {
                this.setState({ recognitionStarted: true });
            }
        }

        if (localStorage.getItem('username')) {
            this.props.getFollowingMovies({}).then(() => {
                this.props.getAllUsers().then(users => {
                    users = users && users.length ? users : []; //users.map(u => ({ label: u.username, value: u.username, email: u.email })) : [];
                    this.setState({ usersCopy: users });
                });
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.title !== this.state.title) {
            if (nextProps.movieError && this.props.movieError !== nextProps.movieError) {
                this.setState({ showAlert: true, alertMsg: nextProps.movieError });
            }
        }
        return true;
    }

    handleSubmit = e => {
        e.preventDefault();
        const { title, rating, type, mode, movieToUpdate } = this.state;
        if (title && rating && type) {
            if (mode === 'Update') {
                this.props.updateMovie({ ...movieToUpdate, rating: rating.value, type: type.value }).then(res => {
                    if (res.title) {
                        this.props.getFollowingMovies({}).then(() => {
                            this.setState({
                                showAlert: true,
                                showMovieModal: false,
                                mode: 'Add',
                                title: null,
                                type: '',
                                rating: null,
                                alertMsg: 'Movie/Show successfully updated'
                            }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
                        });
                    }
                });
            } else {
                this.props.addMovie({ title: title.value, rating: rating.value, type: type.value, watched: true }).then(res => {
                    if (res.title) {
                        this.props.getFollowingMovies({}).then(() => {
                            this.setState({
                                showAlert: true,
                                showMovieModal: false,
                                title: null,
                                type: '',
                                rating: null,
                                alertMsg: 'Movie/Show successfully added to your reviews!'
                            }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
                        });
                    }
                });
            }
        } else {
            this.setState({showAlert: true, alertMsg: 'All fields are required to add movie/show.'}, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
        }

    }

    handleInputChange = (name, value) => {
        console.log('input changed')
        this.setState({ [name]: value })
    }

    handleFollowUser = e => {
        e.preventDefault();
        const { userToFollow } = this.state;
        const { user } = this.props;
        if (userToFollow) {
            this.props.followUser(userToFollow.value).then((res) => {
                if (res.msg) {
                    this.setState({ alertMsg: res.msg, showAlert: true });
                } else {
                    this.props.addNotification({
                        users: [userToFollow.value],
                        type: 'follow',
                        content: `${capitalizeFirstLetter(user.username)} is now following you`
                    }).then(() => this.props.getFollowingMovies({}).then(() => {
                        this.setState({ showAlert: true, alertMsg: `You are now following ${capitalizeFirstLetter(userToFollow.value)}` }, () => setTimeout(() => this.setState({ showAlert: false, userToFollow: null }), 1000));
                    }));
                }
            });
        } else {
            this.setState({ showAlert: true, alertMsg: `Please enter critic's username/email.` }, () => setTimeout(() => this.setState({ showAlert: false }), 700));
        }
    }

    handleNewMovie = () => {
        this.setState(prevState => ({ showMovieModal: !prevState.showMovieModal, titleDisabled: false, title: null, rating: null, type: null }));
    }

    handleVoiceRecognition = () => {
        const { recognitionStarted } = this.state;
        this.setState({
            showAlert: true,
            alertMsg: 'Listening...'
        }, () => {
            !recognitionStarted && setTimeout(() => this.recognition.start(), 100);
        });
    }

    editMovie = (movie) => {
        const { mode } = this.state;
        if (mode === 'Add') {
            const { rating, title, type } = movie;
            this.setState({
                title: { label: title, value: title },
                titleDisabled: true,
                rating: { label: rating === 0 ? 'Bomb' : rating, value: rating },
                type: { label: capitalizeFirstLetter(type), value: type },
                movieToUpdate: movie,
                mode: 'Update',
                showMovieModal: true
            });
            window.scrollTo(0, 0)
        } else {
            this.setState({ mode: 'Add', title: null, rating: null, type: null });
        }
    }

    handleSearchMovie = query => {
        if (query) {
            this.setState({ searchInput: query });
            this.props.searchMovie(query).then(res => {
                this.setState({
                    foundMovies: res.map(m => {
                        const type = TYPE_MAP[m.Type.toLowerCase()]
                        return {
                            label: `${m.Title} - ${m.Year} (${capitalizeFirstLetter(type)})`,
                            value: m.Title,
                            type
                        }
                    }).slice(0, 5)
                });
            }).catch(err => {
                this.setState({ foundMovies: [] })
            });
        } else {
            this.setState({ searchInput: '', foundMovies: [] });
        }
    }

    handleTitleChange = (selectedOption) => {
        if (selectedOption) {
            this.setState({ title: selectedOption, type: {label: capitalizeFirstLetter(selectedOption.type), value: selectedOption.type} });
        } else {
            this.setState({ title: null, type: null });
        }
    }

    handleSearchUsers = (query) => {
        const { usersCopy } = this.state;
        let users;

        if (query.length < 3) {
            users = [];
        } else {
            users = usersCopy.filter(({ username }) => username.toLowerCase().includes(query.toLowerCase())).slice(0, 3).map(u => ({ label: u.username, value: u.username }));
            if (!users.length) {
                users = usersCopy.filter(({ email, privacy }) => privacy === 'public' && email.toLowerCase() === query.toLowerCase()).slice(0, 3).map(u => ({ label: `${u.email} - ${u.username}`, value: u.username }));
            }
        }

        this.setState({
            users
        });
    }

    handleUserSelect = selectedOption => {
        if (selectedOption) {
            this.setState({ userToFollow: selectedOption });
        } else {
            this.setState({ userToFollow: null });
        }
    }

    handleReactSelectInputChange = (input, selectedOption) => {
        if (selectedOption) {
            this.setState({ [input]: selectedOption });
        } else {
            this.setState({ [input]: null });
        }
    }    

    handleRatingInput = query => {
        if (ratingOptions.map(r => r.value).includes(parseInt(query))) {
            this.setState({ ratingInput: query })
        } else {
            this.setState({ ratingInput: '' })
        }
    }

    handleMovieTypeInput = query => {
        if (movieTypeOptions.map(r => r.value).includes(query.toLowerCase())) {
            this.setState({ movieTypeInput: query })
        } else {
            this.setState({ movieTypeInput: '' })
        }
    }      

    render() {
        let { followingMovies, user, movies } = this.props;
        const { rating, type, showMovieModal, showAlert, alertMsg, modalText, showModal, scrollList, userToDelete, userToUnfollow, showWarning, mode, title, foundMovies, searchInput, titleDisabled, modalType, users, userToFollow, ratingInput, movieTypeInput } = this.state;
        const { following, followers } = user;
        followingMovies = followingMovies.map(fm => {
            fm.exist = movies.find(m => m.title === fm.title) ? true : false;
            return fm;
        });
        movies = movies.map(m => { m.exist = true; return m });
        followingMovies = _.uniqBy(followingMovies.concat(movies), m => [m.userId, m.title].join()).sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);

        const customStyles = {
            content: {
                top: '0%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                width: '100%'
            }
        };

        const fullModal = { ...customStyles };
        fullModal.content.height = '100%';

        const followersStyles = {
            content: {
                top: '0%',
                left: '0%',
                right: '0%',
                bottom: '0%',
                width: '100%',
                position: 'static'
            },
            overlay: {
                padding: '15%'
            }
        };

        // Mobile styles
        if (window.innerWidth <= 580) {
            // followersStyles.content.position = 'static';
        }

        return (
            <div>
                <SweetAlert
                    show={showAlert}
                    title={alertMsg}
                    onConfirm={() => {
                        browserIsSupported && this.recognition && this.recognition.stop();
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                    onOutsideClick={() => {
                        browserIsSupported && this.recognition && this.recognition.stop();
                        this.setState({ showAlert: false, recognitionStarted: false });
                    }}
                />
                <SweetAlert
                    show={showWarning}
                    title='Warning'
                    text='You are about to delete a follower or a user that you are following.  Would you like to proceed?'
                    showCancelButton
                    onConfirm={() => {
                        this.setState({ showWarning: false }, () => {
                            if (userToDelete) {
                                this.props.deleteFollower(userToDelete).then(() => this.props.getFollowingMovies({}).then(() => this.setState({ userToDelete: '' })));
                            } else {
                                this.props.unfollowUser(userToUnfollow).then(() => this.props.getFollowingMovies({}).then(() => this.setState({ userToUnfollow: '' })));
                            }
                        })
                    }}
                    onCancel={() => {
                        this.setState({ showWarning: false })
                    }}
                />
                <Modal
                    isOpen={Boolean(scrollList.length)}
                    style={followersStyles}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => this.setState({ scrollList: [] })}
                >
                    <h5>{capitalizeFirstLetter(modalType)} List:</h5>
                    <ul>
                        {scrollList.map((p, i) => <li key={i}><Link className='username' to={`/user/${p}`}>{p}</Link></li>)}
                    </ul>
                </Modal>
                <Modal
                    isOpen={showModal}
                    style={customStyles}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showModal: false })}
                >
                    {modalText}
                </Modal>
                <Modal
                    isOpen={showMovieModal}
                    style={fullModal}
                    contentLabel="Rate A New Movie/Show"
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showMovieModal: false, mode: 'Add' })}
                >
                    <h3 className='App mb-5'>Add Movie/Show Review</h3>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-12 input-group">
                                <Select
                                    id="search-input-select"
                                    value={title}
                                    onChange={this.handleTitleChange}
                                    inputValue={searchInput}
                                    onInputChange={this.handleSearchMovie}
                                    placeholder='Enter Title...'
                                    noOptionsMessage={() => null}
                                    isClearable={true}
                                    isDisabled={titleDisabled}
                                    menuIsOpen={true}
                                    options={foundMovies}
                                    className="reactSelect react-select-lg blue-text"
                                    autoFocus="true"
                                />

                                {browserIsSupported && mode === 'Add' && <span className="input-group-addon">
                                    <i onClick={() => this.handleVoiceRecognition()} className="fas fa-2x fa-microphone"></i>
                                </span>}
                            </div>
                        </div>
                        <div className="form-group mb-0">
                            <Select
                                id="search-input-select"
                                className="reactSelect react-select-lg blue-text"
                                value={rating}
                                onChange={o => this.handleReactSelectInputChange('rating', o)}
                                inputValue={ratingInput}
                                onInputChange={this.handleRatingInput}
                                placeholder='Enter Rating...'
                                noOptionsMessage={() => null}
                                isClearable={true}
                                options={ratingOptions}
                            />
                        </div>
                        <div className="form-group mb-5">
                            <Select
                                id="search-input-select"
                                className="reactSelect react-select-lg blue-text"
                                value={type}
                                onChange={o => this.handleReactSelectInputChange('type', o)}
                                inputValue={movieTypeInput}
                                onInputChange={this.handleMovieTypeInput}
                                placeholder='Select Type...'
                                noOptionsMessage={() => null}
                                isClearable={true}
                                options={movieTypeOptions}
                            />
                        </div>
                        <button type="submit" className="btn btn-lg btn-outline-primary mr-2">{`${mode} Movie/Show Review`}</button>
                        <button type="cancel" className="btn btn-lg btn-outline-primary" onClick={() => this.setState({ showMovieModal: false, mode: 'Add' })}>Close</button>
                    </form>
                </Modal>
                <h2 className="App">Social Movie/Show Reviews</h2>
                <div className="row col-sm-12">
                    <div className="col-12 mb-4">
                        <br />
                        <form
                            className="followers-form row"
                            onSubmit={this.handleFollowUser}>
                            <div className="col-12 pr-0 mb-1">
                                <button type="button" onClick={this.handleNewMovie} className="followForm btn btn-lg btn-outline-primary">Review New Movie/Show</button>
                            </div>
                            <div className="col-12 pr-0 mb-1">
                                <button type="submit" className="followForm btn btn-lg btn-outline-primary">Follow Critic</button>
                            </div>
                            <div className="col-12 pr-0 mb-1">
                                <Select
                                    className="reactSelect react-select-lg blue-text"
                                    value={userToFollow}
                                    onChange={this.handleUserSelect}
                                    onInputChange={this.handleSearchUsers}
                                    placeholder="Enter Critic's Username"
                                    noOptionsMessage={() => null}
                                    isClearable={true}
                                    options={users}
                                    required
                                />
                            </div>
                        </form>
                    </div>
                    {followers && <div className="col-12 col-sm-6">
                        <Link to="#" onClick={() => this.setState({ scrollList: followers, modalType: 'followers' })}><h4>{followers.length} Followers:</h4></Link>
                        <ul className="scroll-bar delete">
                            {followers.map((u, i) => {
                                return (<div key={i}>
                                    <li> <i onClick={() => this.setState({ showWarning: true, userToDelete: u })} className="fas fa-trash-alt"></i> <Link className='username' to={`/user/${u}`}>{u}</Link> </li>
                                </div>)
                            })}
                        </ul>
                    </div>}
                    {following && <div className="col-12 col-sm-6">
                        <Link to="#" onClick={() => this.setState({ scrollList: following, modalType: 'following' })}><h4>Following {following.length}:</h4></Link>
                        <ul className="scroll-bar delete">
                            {following.map((u, i) => {
                                return (<div key={i}>
                                    <li> <i onClick={() => this.setState({ showWarning: true, userToUnfollow: u })} className="fas fa-trash-alt"></i> <Link className='username' to={`/user/${u}`}>{u}</Link> </li>
                                </div>)
                            })}
                        </ul>
                    </div>}

                </div>

                <div>
                    <MovieList showComments titleDisabled={titleDisabled} showAddComment addToWatchList showThumbs showRating showType showRating2 showAgree2 showDisagree2 movies={followingMovies} history={this.props.history} editMovie={this.editMovie} showDeleteMovie showShare />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
        movies: (store.movie.movies || []).filter(m => m.watched),
        // followingMovies: store.movie.followingMovies && store.movie.followingMovies.filter(m => m.watched),
        followingMovies: (store.movie.followingMovies || []).filter(m => m.watched),
        movieError: store.movie.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        followUser: (userToFollow) => dispatch(followUser(userToFollow)),
        getFollowingMovies: (params) => dispatch(getFollowingMovies(params)),
        unfollowUser: user => dispatch(unfollowUser(user)),
        deleteFollower: user => dispatch(deleteFollower(user)),
        rateMovie: movie => dispatch(rateMovie(movie)),
        addMovie: movie => dispatch(addMovie(movie)),
        updateMovie: movie => dispatch(updateMovie(movie)),
        addNotification: notification => dispatch(addNotification(notification)),
        searchMovie: query => dispatch(searchMovie(query)),
        getAllUsers: () => dispatch(getAllUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
