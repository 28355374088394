import {
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    FOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_PUBLIC_PROFILE_SUCCESS,
    GET_PUBLIC_PROFILE_FAILURE,
    DELETE_FOLLOWER_SUCCESS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE
} from "../actions/actionTypes";

const initialState = {
    followers: [],
    following: [],
    error: '',
    publicProfile: {},
    all: []
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER_SUCCESS:
            return action.payload
        case CREATE_USER_FAILURE:
            return { ...state, ...action.payload };
        case LOGIN_USER_SUCCESS:
            return { ...state, ...action.response };
        case LOGIN_USER_FAILURE:
            return action.error;
        case GET_USER_SUCCESS:
            return { ...state, ...action.payload };
        case GET_USER_FAILURE:
            return action.payload;
        case GET_ALL_USERS_SUCCESS:
            return { ...state, all: action.payload };
        case GET_ALL_USERS_FAILURE:
            return action.payload;
        case GET_PUBLIC_PROFILE_SUCCESS:
            return { ...state, publicProfile: action.payload };
        case GET_PUBLIC_PROFILE_FAILURE:
            return action.payload;
        case UPDATE_USER_SUCCESS:
            return { ...state, ...action.payload };
        case UPDATE_USER_FAILURE:
            return action.error;
        case FOLLOW_USER_SUCCESS:
            return { ...state, ...action.payload };
        case UNFOLLOW_USER_SUCCESS:
            return { ...action.payload };
        case DELETE_FOLLOWER_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
}

export default userReducer
