import axios from "axios";
import _ from 'lodash';
import {
    ADD_MOVIE_SUCCESS,
    ADD_MOVIE_FAILURE,
    GET_USER_MOVIES_SUCCESS,
    GET_USER_MOVIES_FAILURE,
    GET_ONLINE_MOVIES_SUCCESS,
    GET_ONLINE_MOVIES_FAILURE,
    UPDATE_MOVIE_SUCCESS,
    UPDATE_MOVIE_FAILURE,
    DELETE_MOVIE_SUCCESS,
    DELETE_MOVIE_FAILURE,
    IMPORT_MOVIES_SUCCESS,
    IMPORT_MOVIES_FAILURE,
    GET_FOLLOWING_MOVIES_SUCCESS,
    GET_FOLLOWING_MOVIES_FAILURE
} from '../actions/actionTypes';
import moment from "moment";

const TYPE_MAP = {
    tv: 'show',
    movie: 'movie'
}

export const addMovie = (data) => {
    const token = localStorage.getItem('token');
    return dispatch => {
        return axios.post('/api/movies', data, {
            headers: {
                token
            }
        }).then(res => {
            dispatch({ type: ADD_MOVIE_SUCCESS, payload: res.data });
            return res.data;
        }).catch(err => {
            dispatch({ type: ADD_MOVIE_FAILURE, payload: err.response.data.err })
            return err.response.data;
        });
    }
};

export const addMovieComment = (data) => {
    return dispatch => {
        return axios.patch(`/api/movies/add-comment/${data.movieId}`, data, {
            headers: {
                token: localStorage.getItem('token')
            }
        }).then(res => {
            dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: res.data });
            return res.data;
        }).catch(err => {
            dispatch({ type: UPDATE_MOVIE_FAILURE, payload: 'Unable to add comment to Movie' })
            return err;
        });
    }
};

export const deleteMovieComment = ({movieId, commentId}) => {
    return dispatch => {
        return axios.patch(`/api/movies/delete-comment/${movieId}`, {commentId}, {
            headers: {
                token: localStorage.getItem('token')
            }
        }).then(res => {
            dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: res.data });
            return res.data;
        }).catch(err => {
            dispatch({ type: UPDATE_MOVIE_FAILURE, payload: 'Unable to add comment to Movie' })
            return err;
        });
    }
};

export const getOnlineMovies = ({ type, sort, period, page, minRating, genre }) => {
    let url = `https://api.themoviedb.org/3/discover/${type}?api_key=${process.env.REACT_APP_TMDB_APIKEY}&with_original_language=en&sort_by=${sort}.desc&include_adult=false&include_video=false&page=1&primary_release_date.gte=${period}-01-01&primary_release_date.lte=${period}-12-31&vote_count.gte=100&page=${page}&vote_average.gte=${minRating}&with_genres=${genre}`;
    if (period === 'all') {
        url = `https://api.themoviedb.org/3/discover/${type}?api_key=${process.env.REACT_APP_TMDB_APIKEY}&with_original_language=en&sort_by=${sort}.desc&include_adult=false&include_video=false&page=1&vote_count.gte=100&page=${page}&vote_average.gte=${minRating}&with_genres=${genre}`;
    }
    return dispatch => {
        return axios.get(url)
            .then(res => {
                res.data.results.reverse();
                dispatch({ type: GET_ONLINE_MOVIES_SUCCESS, payload: res.data.results });
                return res.data;
            }).catch(err => {
                dispatch({ type: GET_ONLINE_MOVIES_FAILURE, payload: 'The TMDB recommendations server that powers this page is down for a few hours for maintanence/repairs.' });
                return Promise.reject({err});
            })
            /*{switch .then and .catch to show tmdb server down sweetalert}
            return dispatch => {
               return axios.get(url)
            .catch(res => {
                res.data.results.reverse();
                dispatch({ type: GET_ONLINE_MOVIES_SUCCESS, payload: res.data.results });
                return res.data;
            }).then(err => {
                dispatch({ type: GET_ONLINE_MOVIES_FAILURE, payload: 'The TMDB recommendations server that powers this page is down for a few hours for maintanence/repairs.' });
                return Promise.reject({err});
            })         
            */   
    }
}

export const getUserMovies = (username) => {
    return dispatch => {
        return axios.get(
            `/api/movies/user-movies/${username}`
            , {
                headers: {
                    token: localStorage.getItem('token')
                }
            })
            .then(res => {
                res.data.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)
                dispatch({ type: GET_USER_MOVIES_SUCCESS, payload: res.data });
                return res.data;
            }).catch(err => {
                dispatch({ type: GET_USER_MOVIES_FAILURE, payload: err });
                return err;
            })
    };
};

export const getFollowingMovies = ({ type, sort, period, page, minRating }) => {
    return dispatch => {
        return axios.get(
            "/api/movies/following-movies"
            , {
                headers: {
                    token: localStorage.getItem('token')
                }
            })
            .then(res => {
                if (type) {
                    res.data = res.data.filter(m => m.type === TYPE_MAP[type]);
                }
                if (minRating) {
                    res.data = res.data.filter(m => m.rating >= minRating)
                }
                if (sort) {
                    const groupedMovies = _.groupBy(res.data, 'title');
                    const aveRatingMap = {};
                    Object.keys(groupedMovies).forEach(m => {
                        const eachGroup = groupedMovies[m];
                        aveRatingMap[m] = eachGroup.map(m => m.rating).sort().reduce((a, b) => a + b) / eachGroup.length;
                    });    
                    res.data = _.uniqBy(res.data, m => m.title.toLowerCase()).map(m => ({ ...m, rating: aveRatingMap[m.title] || '', voteCount: groupedMovies[m.title].length }));

                    const voteCountMap = {};
                    Object.keys(groupedMovies).forEach(m => {
                        voteCountMap[m] = groupedMovies[m].length;
                    });
                    res.data = _.uniqBy(res.data, m => m.title.toLowerCase()).map(m => ({ ...m, voteCount: voteCountMap[m.title] }));
                    switch (sort) {
                        case 'vote_average':
                            res.data.sort((a, b) => a.rating > b.rating ? 1 : -1);
                            break;
                        case 'vote_count':
                        case 'popularity':
                            res.data.sort((a, b) => a.voteCount > b.voteCount ? 1 : -1);
                            break;
                        case 'release_date':
                            res.data.sort((a, b) => moment(a.releaseDate) > moment(b.releaseDate) ? 1 : -1);
                            break;
                        default:
                            break;
                    }
                }
                if (period && period !== 'all') {
                    res.data = res.data.filter(m => new Date(m.releaseDate).getFullYear() === period)
                }
                const unpaginatedResponse = res.data;
                if (page) {
                    const pageStart = (page - 1) * 20;
                    res.data = res.data.slice(pageStart, pageStart + 20);
                }
                dispatch({ type: GET_FOLLOWING_MOVIES_SUCCESS, movies: res.data });
                return unpaginatedResponse;
            }).catch(err => {
                dispatch({ type: GET_FOLLOWING_MOVIES_FAILURE, error: err });
                return err;
            })
    };
};

export const updateMovie = (movie) => {
    return dispatch => {
        return axios.patch(`/api/movies/${movie.id || movie._id}`, movie, {
            headers: {
                token: localStorage.getItem('token')
            }
        })
            .then(res => {
                dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: res.data });
                return res.data;
            })
            .catch(err => {
                dispatch({ type: UPDATE_MOVIE_FAILURE, payload: err });
                return err
            });
    }
}

export const rateMovie = ({ type, movieId }) => {
    return dispatch => {
        return axios.patch(`/api/movies/rate/${movieId}`, {
            type
        }, {
                headers: {
                    token: localStorage.getItem('token')
                }
            })
            .then(res => {
                dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: res.data });
                return res.data;
            })
            .catch(err => dispatch({ type: UPDATE_MOVIE_FAILURE, payload: err }));
    }
}

export const unrateMovie = ({ type, movieId }) => {
    return dispatch => {
        return axios.patch(`/api/movies/unrate/${movieId}`, {
            type
        }, {
                headers: {
                    token: localStorage.getItem('token')
                }
            })
            .then(res => {
                dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: res.data });
                return res.data;
            })
            .catch(err => dispatch({ type: UPDATE_MOVIE_FAILURE, payload: err }));
    }
}

export const deleteMovie = (id) => {
    return dispatch => {
        return axios.delete(`/api/movies/${id}`, {
            headers: {
                token: localStorage.getItem('token')
            }
        })
            .then(res => dispatch({ type: DELETE_MOVIE_SUCCESS, movie: res.data }))
            .catch(err => dispatch({ type: DELETE_MOVIE_FAILURE, err }))
    }
}

export const importMovies = (username) => {
    return (dispatch) => {
        return axios.post('/api/movies/import', {
            username
        }, {
                headers: {
                    token: localStorage.getItem('token')
                }
            }).then(res => {
                dispatch({ type: IMPORT_MOVIES_SUCCESS, movies: res.data });
                return res.data;
            }).catch(err => {
                dispatch({ type: IMPORT_MOVIES_FAILURE, payload: err });
                return err;
            });
    }
}

export const importMoviesJson = (formData) => {
    const data = new FormData();
    data.append('file', formData);
    return (dispatch) => {
        return axios.post('/api/movies/json-import', data, {
            headers: {
                token: localStorage.getItem('token')
            }
        }).then(res => {
            dispatch({ type: IMPORT_MOVIES_SUCCESS, movies: res.data });
            return res.data;
        }).catch(err => {
            dispatch({ type: IMPORT_MOVIES_FAILURE, payload: err });
            return {err};
        });
    }
}

export const searchMovie = query => {
    return dispatch => {
        return axios.post(`https://omdbapi.com/?s=${encodeURIComponent(query)}&apikey=${process.env.REACT_APP_OMDB_APIKEY}`)
        .then(res => {
            if (res.data.Search) {
                return res.data.Search
            }
            return Promise.reject({err: res.data});
        }).catch(err => {
            return Promise.reject({err: err});
        });
    }
}