import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './index.css';
import './App.css'
import Movies from './components/Movie';
import ExportMovies from './components/Movie/MovieExport';
import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Movie/Home';
import ProfileSettings from './components/ProfileSettings';
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
import ProfilePage from './components/ProfilePage';
import ToWatchList from './components/Movie/ToWatchList';
import Recommendations from './components/Movie/Recommendations';
import Notifications from './components/Movie/Notifications';
import Feedback from './components/Feedback';
import Install from './components/Install';
import Guide from './components/Guide';
import BeginPasswordReset from './components/BeginPasswordReset'
import FinishPasswordReset from './components/FinishPasswordReset'
import store from './store/store';
import { getLoggedInUser } from './actions/userActions';
import * as serviceWorker from './serviceWorker';
import Messages from './components/Messages';
import AppContext from './AppContext';

Modal.setAppElement('#root')

if (localStorage.getItem('token')) {
  store.dispatch(getLoggedInUser());
}


function Routes() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <AppContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      <Provider store={store}>
        <BrowserRouter>
          <div className="container">
            <Header />
            <Switch>
              <Route exact path="/register" component={Register} />
              <Route exact path="/" component={Login} />
              <Route path="/movies" component={Movies} />
              <Route path="/import-movies" component={ExportMovies} />
              <Route path="/home" component={Home} />
              <Route path="/profile-settings" component={ProfileSettings} />
              <Route path="/profilesettings" component={ProfileSettings} />
              <Route path="/to-watch-list" component={ToWatchList} />
              <Route path="/recommendations" component={Recommendations} />
              <Route path="/user/:username" component={ProfilePage} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/begin-password-reset" component={BeginPasswordReset} />
              <Route path="/password-reset/:username/:resetToken" component={FinishPasswordReset} />
              <Route path="/privacy" component={Feedback} />
              <Route path="/guide" component={Guide} />
              <Route path="/install" component={Install} />
              <Route path="/messages" component={Messages} />
              <Redirect from="*" to="/" />
            </Switch>
            
            <br />
          </div>
        </BrowserRouter>
      </Provider>
    </AppContext.Provider>
  )
}


ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});

