import React from 'react';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import Modal from 'react-modal';
import Select from "react-select";
import "../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import { importMoviesJson, getUserMovies } from '../actions/movieActions';
import { updateUser } from '../actions/userActions';

const PRIVACY_MAP = {
    default: { label: 'Default', value: 'default' },
    public: { label: 'Public', value: 'public' },
    private: { label: 'Private', value: 'private' }
}

const BOOLEAN_MAP = {
    [false]: { label: 'Off', value: false },
    [true]: { label: 'On', value: true }
}

const AVATAR_MAP = {
    xkvile0: { label: 'Cat Critic', value: 'xkvile0' },
    mO7TUQX: { label: 'Dog Critic', value: 'mO7TUQX' },
    bv2iuVB: { label: 'Dragon Critic', value: 'bv2iuVB' },
    qOGtphP: { label: 'Owl Critic', value: 'qOGtphP' },
    kfjgYut: { label: 'Rabbit Critic', value: 'kfjgYut' },
    mciDQMC: { label: 'Raccoon Critic', value: 'mciDQMC' },
    hg72RVO: { label: 'Panda Critic', value: 'hg72RVO' }
}

class ProfileSettings extends React.Component {
    state = {
        showModal: false,
        userEmail: '',
        email: '',
        password: '',
        showAlert: false,
        alertMsg: '',
        jsonFile: '',
    }

    componentWillMount() {
        if (!localStorage.getItem('username')) {
            this.props.history.replace('/');
        }
    }

    componentDidUpdate(prevProps) {
        const existingEmail = this.props.user.email;
        if (existingEmail && prevProps.user.email !== existingEmail) {
            this.setState({ email: existingEmail });
        }
    }

    handleProfileImage = newImage => {
        this.handleUserUpdate({ avatarUrl: `https://i.imgur.com/${newImage}.jpg` })
        window.scrollTo(0, 0)
    }

    handleInputChange = (prop, value) => {
        this.setState({ [prop]: value })
    }

    handleUserUpdate = async updates => {
        const { user } = this.props;
        return this.props.updateUser({ username: user.username, ...updates }).then(res => {
            if (res.email) {
                this.setState({ showAlert: true, alertMsg: 'Profile settings updated successfully.' }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
            } else {
                this.setState({ showAlert: false, alertMsg: res.err });
            }
        })
    }

    checkUserEmail = newState => {
        const { user } = this.props;
        if (!user.email) {
            this.setState({ showModal: true });
        } else {
            this.handleUserUpdate(newState)
        }
    }

    addUserEmail = e => {
        e.preventDefault();
        this.handleUserUpdate({ receiveEmailAlerts: true, email: this.state.userEmail }).then(() => this.setState({ showModal: false }));
    }

    handleSubmit = (event, type) => {
        event.preventDefault();
        const username = localStorage.getItem('username');
        const { jsonFile } = this.state;
        if (type === 'export') {
            this.props.getUserMovies(username).then(movies => {
                FileDownload(JSON.stringify(movies, null, 2), 'my-movies.json');
            });
        } else if (type === 'import-json' && jsonFile) {
            this.props.importJson(jsonFile).then(resp => {
                if (resp.err) {
                    this.setState({ showAlert: true, alertMsg: `Failed to import movie reviews.` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
                } else {
                    this.setState({ showAlert: true, alertMsg: `Successfully imported movie reviews.` }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
                }
            });
        }
    }

    render() {
        const { user } = this.props;
        const { showModal, userEmail, email, password, showAlert, alertMsg, privacy } = this.state;
        const { avatarUrl } = user;
        const avatar = avatarUrl && avatarUrl.split(".com/")[1].split(".")[0];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        return (
            <div className="logo-shift wideFix">
                <Modal
                    isOpen={showModal}
                    style={customStyles}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showModal: false })}
                >
                    <form onSubmit={this.addUserEmail}>
                        <h3>Please enter your Email Address</h3>
                        <input className="form-control" name="userEmail" value={userEmail} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                        <button>Update</button>
                    </form>
                </Modal>
                <SweetAlert
                    show={showAlert}
                    title={alertMsg}
                    onConfirm={() => {
                        this.setState({ showAlert: false });
                    }}
                    onOutsideClick={() => {
                        this.setState({ showAlert: false });
                    }}
                />
                <h2 className='App'>Profile Settings</h2>
                <br />
                <Link to="#"> <h3 className='username'>{localStorage.getItem('username')}</h3></Link>
                <Link to="#"> <img className='mb-3' src={avatarUrl} alt="" width="250" height="250" /> </Link>
                <div className="form-group">
                    <br />
                    <h3 className='App'>Privacy Setting Information:</h3>
                    <p>
                        <br />
                    Note - Your WatchList (ToWatch List) will always be private regardless of your chosen privacy setting.<br />
                        <br />
                        <h5>Public:</h5>
              Your reviews are visible to: Anyone with a link to your profile page.<br />
              Your Username is Visible In Search Suggestions: When a user correctly enters the first 3 characters of your username or correctly enters your full email address.<br />
              You can be sent a message by: Anyone with a MovieCritic account.<br />
              You can be followed by: Anyone with a MovieCritic account.<br />
                        <br />
                        <h5>Default: </h5>
              Your reviews are visible to: Only you and your followers on MovieCritic.<br />
              Your Username is Visible In Search Suggestions: When a user correctly enters the first 3 characters of your username.<br />
              You can be sent a message by: Anyone with a MovieCritic account.<br />
              You can be followed by: Anyone with a MovieCritic account.<br />
                        <br />
                        <h5>Private: </h5>
              Your reviews are visible to: Only you.<br />
              Your Username is Visible In Search Suggestions: Never.<br />
              You can be sent a message by: No other MovieCritic user.<br />
              You can be followed by: No other MovieCritic user.<br />
                        <br />
                    </p>
                    <h3 className='App'>Your Privacy Settings:</h3>
                    <Select
                        className="reactSelect react-select-lg blue-text"
                        value={PRIVACY_MAP[user.privacy]}
                        onChange={({ value }) => this.handleUserUpdate({ privacy: value })}
                        options={Object.values(PRIVACY_MAP)}
                        required
                    />
                </div>

                <div className="form-group mt-2">
                    <h3 className='App'>Notification Email Alerts: </h3>
                    <Select
                        className="reactSelect react-select-lg blue-text"
                        value={BOOLEAN_MAP[user.receiveEmailAlerts]}
                        onChange={({ value }) => this.handleUserUpdate({ receiveEmailAlerts: value })}
                        options={Object.values(BOOLEAN_MAP)}
                        required
                    />
                </div>

                <div className="form-group mt-2">
                    <h3 className='App'>Message Email Alerts: </h3>
                    <Select
                        className="reactSelect react-select-lg blue-text"
                        value={BOOLEAN_MAP[user.receiveMessageEmails]}
                        onChange={({ value }) => this.handleUserUpdate({ receiveMessageEmails: value })}
                        options={Object.values(BOOLEAN_MAP)}
                        required
                    />
                </div>

                <div className="form-group">
                    <h3 className='App'>Change Profile Avatar:</h3>
                    <Select
                        className="reactSelect react-select-lg blue-text"
                        value={AVATAR_MAP[avatar]}
                        onChange={({ value }) => this.handleProfileImage(value)}
                        options={Object.values(AVATAR_MAP)}
                        required
                    />
                </div>

                <div>
                    <h3 className='App'>Update Email Address/Password:</h3>
                    <form onSubmit={e => {
                        e.preventDefault();
                        this.handleUserUpdate({ email });
                    }}>
                        <label htmlFor="email">Email Address</label>
                        <div className="input-group">
                            <input autoCorrect="off" autoComplete="new-email" autoCapitalize="none" required type="email" className="form-control mr-3" name="email" aria-describedby="usernameOrEmailHelp" placeholder="Please Enter Your Username." value={email} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </form>
                    <form onSubmit={e => {
                        e.preventDefault();
                        this.handleUserUpdate({ password });
                    }}>
                        <label className='mt-2' htmlFor="password">Password</label>
                        <div className="input-group">
                            <input autoCorrect="off" autoComplete="new-password" autoCapitalize="none" required type="password" className="form-control mr-3" name="password" placeholder="Password" value={password} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </form>
                    <br />

                    <h3 className='App'>Profile Page Link:</h3><br />
                    <a className='App' href={`/user/${localStorage.getItem('username')}`}>{window.location.host}/user/{localStorage.getItem('username')}</a>
                    <br />
                    <br />
                </div>

                <h3>Import Movie/Show Reviews From Exported File (JSON Format):</h3>
                <form onSubmit={(event) => this.handleSubmit(event, 'import-json')}
                    encType="multipart/form-data">
                    <div className="form-group">
                        <input type="file"
                            name="json-file"
                            required
                            onChange={e => this.setState({ jsonFile: e.target.files[0] })}
                            accept=".json"
                            style={{ marginRight: '20px' }} />
                        <button type="submit" className="btn btn-outline-primary float-right btn-lg">Import</button>
                    </div>
                </form>
                <h3>Export Movie/Show Reviews To File (JSON Format):</h3>
                <form onSubmit={(event) => this.handleSubmit(event, 'export')}>
                    <div className="form-group">
                        <label style={{ marginRight: '20px' }}>Export your movie/show reviews by clicking the "export" button. </label>
                        <button type="submit"
                            className="btn btn-outline-primary float-right btn-lg">Export</button>
                    </div>
                </form>
            </div >
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: info => dispatch(updateUser(info)),
        getUserMovies: (username) => dispatch(getUserMovies(username)),
        importJson: (formData) => dispatch(importMoviesJson(formData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
