import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import socketIo from 'socket.io-client';
import { connect } from 'react-redux';
import { getUserNotifications } from '../../actions/notificationActions';
import AppContext from '../../AppContext';


class Header extends Component {
  static contextType = AppContext;
  state = {
    unreadMsgs: 0
  }

  componentDidMount() {
    console.log('this.conn didm==>>>', this.context.isMenuOpen)
    this.username = localStorage.getItem('username');
    if (this.username) {
      this.props.getUserNotifications();

      this.socket = socketIo({
        query: {
          username: this.username
        }
      });

      this.socket.on('recipients', recipients => {
        this.setState({ unreadMsgs: recipients.map(r => r.unread).reduce((a, b) => a + b, 0) });
      });
    }

    this.closeMenu = this.closeMenu.bind(this);

    window.addEventListener('click', this.closeMenu);
  }

  componentDidUpdate() {
    console.log('this.conn did==>>>', this.context.isMenuOpen)
  }

  closeMenu = (event) => {
    const dontClose = !this.context.isMenuOpen || 
      event.target.closest('#menus') ||
      ['SELECT', 'BUTTON', 'I', 'DIV'].includes(event.target.tagName)

    if (dontClose) {
      return;
    }

    this.context.setIsMenuOpen(true)
    setTimeout(
      () => this.context.setIsMenuOpen(false)
      , 10)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeMenu);
  }

  logoutUser = () => {
    localStorage.clear();
  }

  handleMenuStateChange = ({isOpen}) => {
    this.context.setIsMenuOpen(isOpen);
  }

  render() {
    const { isMenuOpen } = this.context;
    const { unreadMsgs } = this.state;
    const { notifications, user } = this.props;
    const { avatarUrl } = user;

    var styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: '60px',
        height: '45px',
        right: '30px',
        top: '20px'
      },
      bmBurgerBars: {
        background: '#373a47'
      },
      bmBurgerBarsHover: {
        background: '#a90000'
      },
      bmCrossButton: {
        height: '36px',
        width: '36px'
      },
      bmCross: {
        background: '#bdc3c7',
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%'
      },
      bmMenu: {
        background: '#373a47',
        padding: '2.5em 1.5em 0',
        fontSize: '.9em'
      },
      bmMorphShape: {
        fill: '#373a47'
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em'
      },
      bmOverlay: {
        //background: 'rgba(0, 0, 0, 0.3)'
      },
      bmItem: {
        // display: 'inline-block'
      }
    }
    return (
      <div className="Header container position-relative">
        <div>
          <NavLink to={localStorage.getItem('token') ? "/home" : '/'} style={{ display: 'inline-block' }}><img className="logo" width="150" alt="" height="150" src="https://res.cloudinary.com/dm9emvsjt/image/upload/v1585585935/MovieCriticsvg_xvrc9i.svg" /></NavLink>
        </div>
        <div className="float-right menus" id="menus" >
          {localStorage.getItem('token') ?
            (
              <Menu isOpen={isMenuOpen} disableAutoFocus styles={styles} right onStateChange={this.handleMenuStateChange}>
                <Link to={`/user/${localStorage.getItem('username')}`}> <h3 className='username'>{localStorage.getItem('username')}</h3></Link>
                <Link to={`/user/${localStorage.getItem('username')}`} className="no-outline">
                  <img src={avatarUrl} width="100" height="100" href="/home" alt="" /> </Link>
                <hr />
                <Link to="/home" id="home" className="menu-item--small">Home/Social Reviews</Link>
                <Link to="/movies" id="about" className="menu-item--small" >My Reviews</Link>
                <Link to="/to-watch-list" className="menu-item--small">My ToWatch List</Link>
                <Link to="/recommendations" className="menu-item--small" >Movie Recommendations</Link>
                <Link to="/notifications" className="menu-item--small" >Notifications - {notifications.length} New</Link>
                <Link to="/messages" className="menu-item--small" >Messages - {unreadMsgs} New</Link>
                <Link to={`/user/${localStorage.getItem('username')}`} className="menu-item--small" >Profile Page</Link>
                <Link to="/profile-settings" className="menu-item--small" >Profile Settings</Link>
                <Link to="/privacy" className="menu-item--small" >Feedback/Privacy</Link>
                <Link to="/install" className="menu-item--small" >Android/iPhone Installation</Link>
                <Link to="/guide" className="menu-item--small" >Guide/Getting Started</Link>
                <Link to="/login" onClick={this.logoutUser} >Logout</Link>
              </Menu>

            ) :
            (
              <Menu isOpen={isMenuOpen} disableAutoFocus styles={styles} onStateChange={this.handleMenuStateChange} right>
                <Link to="/register" onClick={this.logoutUser} >Register</Link>
                <Link to="/" onClick={this.logoutUser} >Log In</Link>
                <Link to="/guide" onClick={this.logoutUser} >Guide/Getting Started</Link>
                <Link to="/privacy" onClick={this.logoutUser} >Feedback/Privacy</Link>
                <Link to="/install" onClick={this.logoutUser} >Android/iPhone Installation</Link>
              </Menu>

            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    notifications: store.notification.notifications.filter(n => store.user.username === n.userId && !n.seen)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserNotifications: notification => dispatch(getUserNotifications(notification))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
