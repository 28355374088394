import axios from 'axios';

export const requestPasswordReset = userInfo => {
    return dispatch => {
        return axios.post(`/api/password-reset`, userInfo).then(res => {
            return res;
        }).catch(err => {
            console.log('err pwd reset', err);
            return err.response.data;
        });
    }
}

export const verifyPasswordReset = resetToken => {
    return dispatch => {
        return axios.get(`/api/password-reset/verify/${resetToken}`, {
            headers: {
                token: localStorage.getItem('token')
            }
        }).then(res => {
            return res.data;
        }).catch(err => {
            return err;
        })
    }
}