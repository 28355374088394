export const capitalizeFirstLetter = string => string && string.split(' ').map(w => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');

export const decodeJwtToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(base64);
};

export const toQueryString = (params) => {
    return Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');    
}