import axios from "axios";
import { ADD_NOTIFICATION_SUCCESS, ADD_NOTIFICATION_FAILURE, GET_USER_NOTIFICATIONS_SUCCESS, GET_USER_NOTIFICATIONS_FAILURE, MARK_USER_NOTIFICATIONS_SUCCESS, MARK_USER_NOTIFICATIONS_FAILURE } from "./actionTypes";
import { capitalizeFirstLetter } from "../utils";
import moment from 'moment-timezone';

export const addNotification = body => {
  return dispatch => {
    return axios({
      url:
        "/api/notifications",
      method: "POST",
      data: body,
      headers: {
        token: localStorage.getItem('token')
      }
    })
      .then((response) => {
        dispatch({ type: ADD_NOTIFICATION_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: ADD_NOTIFICATION_FAILURE, payload: error });
        return error;
      });
  };
};

export const getUserNotifications = () => {
  return (dispatch) => {
    return axios.get(`/api/notifications`, {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {
      dispatch({
        type: GET_USER_NOTIFICATIONS_SUCCESS,
        payload: res.data
      });
      return res.data;
    }).catch(err => {
      dispatch({
        type: GET_USER_NOTIFICATIONS_FAILURE,
        payload: err
      });
      return err;
    });
  }
}

export const markNotificationsAsSeen = () => {
  return dispatch => {
    return axios({
      url:
        "/api/notifications",
      method: "PATCH",
      data: {},
      headers: {
        token: localStorage.getItem('token')
      }
    })
      .then((response) => {
        dispatch({ type: MARK_USER_NOTIFICATIONS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: MARK_USER_NOTIFICATIONS_FAILURE, payload: error });
        return error;
      });
  };
}

export const sendEmail = (emails, movie) => {
  const loggedInUser = localStorage.getItem('username');
  const content = `
    <h3>
    <a href=${window.location.origin}/user/${loggedInUser} >${capitalizeFirstLetter(loggedInUser)}</a> shared the below movie/show recomendation with you.<br/>
    <br/>
    ${capitalizeFirstLetter(movie.title)}<br/>
    <a href=${window.location.origin}/user/${movie.userId} >${capitalizeFirstLetter(movie.userId)}</a>'s Rating ${movie.imdbRating ? `(IMDB: ${movie.imdbRating})` : movie.tmdbRating ? `(TMDB: ${movie.tmdbRating})` : '' }<br/>
    Type: ${capitalizeFirstLetter(movie.type)} (${moment(movie.releaseDate).format('YYYY')} )<br/>
    Rated on: ${moment(movie.updatedAt).tz('America/New_York').format('MMMM Do YYYY, h:mm a')}<br/>
    <br/>
    <img src=${movie.imgUrl} />
    </h3>
  `
  return dispatch => {
    return axios({
      url:
        "/api/notifications/send-email",
      method: "POST",
      data: { emails, content },
      headers: {
        token: localStorage.getItem('token')
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };
}