import React from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import Modal from 'react-modal';
import _ from 'lodash';
import SweetAlert from 'sweetalert-react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share'
import { rateMovie, unrateMovie, addMovie, deleteMovie, addMovieComment, getUserMovies, updateMovie, getFollowingMovies, deleteMovieComment } from '../../actions/movieActions';
import { addNotification, sendEmail } from '../../actions/notificationActions';
import { getAllUsers, getUserInfo } from '../../actions/userActions';
import { capitalizeFirstLetter } from '../../utils';

const TYPE_MAP = {
  show: 'series',
  movie: 'movie',
  series: 'show',
  showShareModal: false,

}

class MovieList extends React.Component {
  state = {
    showAlert: false,
    alertMsg: '',
    scrollList: [],
    showModal: false,
    type: '',
    rating: '',
    selectedMovie: false,
    movieComments: '',
    showCancelButton: false,
    movieToReview: {},
    movieToDelete: null,
    commentToDelete: null,
    title: null,
    searchInput: '',
    foundMovies: [],
    modalType: '',
    toShareList: '',
    movieToShare: {},
    alertType: '',
    movieComments: [],
    movieToUnrate: null,
    shareToUser: null,
    users: [],
    usersCopy: []
  }

  componentDidMount() {
    this.props.getUserMovies(localStorage.getItem('username')).then(() => {
      this.props.getAllUsers().then(users => {
        users = users && users.length ? users.map(u => ({ label: u.username, value: u.username })) : [];
        this.setState({ usersCopy: users });
      });
    })
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.movieError && this.props.movieError !== nextProps.movieError) {
      this.setState({ showAlert: true, alertMsg: nextProps.movieError }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    }
    return true;
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  handleReviewMovie = e => {
    e.preventDefault();
    const userId = localStorage.getItem('username');
    const { myTowatchList } = this.props;
    const { movieToReview, rating, type } = this.state;
    const movie = myTowatchList.find(m => m.title.toLowerCase() === movieToReview.title.toLowerCase());
    if (movie) {
      this.props.updateMovie({ ...movie, userId, watched: true, rating, type }).then(res => {
        if (res.title) {
          this.setState({ showAlert: true, alertMsg: 'Movie/Show rated successfully.  Movie has been moved from ToWatch List to your Movie Reviews.', showReviewModal: false }, () => setTimeout(() => this.setState({ showAlert: false }), 1500));
        }
      })
    } else {
      this.props.addMovie({ title: movieToReview.title, rating, type, watched: true }).then(res => {
        if (res.title) {
          this.setState({
            showAlert: true,
            showReviewModal: false,
            alertMsg: 'Movie/Show successfully added to your reviews!'
          }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
        }
      })
    }
  }

  handleShowComments = movie => {
    this.setState({
      showModal: true,
      movieComments: movie.comments.map(c => ({ ...c, movieId: movie._id })),
      modalType: 'comments'
    })
  }

  handleRateMovie = (movie, type) => {
    const { rateMovie, addNotification, user } = this.props;
    rateMovie({ movieId: movie._id, type }).then(() => addNotification({
      users: [movie.userId],
      type,
      content: `${capitalizeFirstLetter(user.username)} ${type}d with your review of - ${capitalizeFirstLetter(movie.title)}`
    }))
  }

  handleAddMovie = movie => {
    this.props.addMovie({ ...movie, rating: '', watched: false }).then(() => this.setState({
      showAlert: true,
      alertMsg: 'Movie Added To Your WatchList.'
    }, () => setTimeout(() => this.setState({ showAlert: false }), 1000)));
  }

  handleAddComment = e => {
    e.preventDefault();
    const { comment, selectedMovie } = this.state;
    const { username } = this.props.user;
    this.props.addMovieComment({ comment, movieId: selectedMovie._id }).then(() => {
      this.props.addNotification({
        users: _.uniq([selectedMovie.userId, username, ...selectedMovie.comments.map(c => c.user)]),
        type: 'comment',
        content: `${capitalizeFirstLetter(username)} commented on your review of ${selectedMovie.title}`
      }).then(() => this.setState({ selectedMovie: false, comment: '' }));
    });
  }

  handleSearchMovie = query => {
    if (query) {
      this.setState({ searchInput: query });
      this.props.searchMovie(query).then(res => {
        this.setState({
          foundMovies: res.map(m => {
            const type = TYPE_MAP[m.Type.toLowerCase()]
            return {
              label: `${m.Title} - ${m.Year} (${capitalizeFirstLetter(type)})`,
              value: m.Title,
              type
            }
          }).slice(0, 5)
        });
      }).catch(err => {
        this.setState({ foundMovies: [] })
      });
    } else {
      this.setState({ searchInput: '', foundMovies: [] });
    }
  }

  handleTitleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ title: selectedOption, type: selectedOption.type });
    } else {
      this.setState({ title: null, type: '' });
    }
  }

  shareMovie = e => {
    e && e.preventDefault();
    const { toShareList, movieToShare, shareToUser } = this.state;
    const emails = shareToUser && shareToUser.email ? [shareToUser.email] : toShareList.trim().split(/[ ,]+/).filter(Boolean);
    this.props.sendEmail(emails, movieToShare).then(res => {
      this.setState({ showAlert: true, alertMsg: 'Your movie/show recommendation was successfully submitted/sent.' }, () => setTimeout(() => this.setState({ showAlert: false }), 1000));
    }).catch(err => {
      this.setState({ showAlert: true, alertMsg: 'Error sending Recommendation.' });
    });
  }

  handleSearchUsers = query => {
    const { usersCopy } = this.state;
    let users;

    if (query.length < 3) {
        users = [];
    } else {
        users = usersCopy.filter(({ value }) => value.toLowerCase().includes(query.toLowerCase())).slice(0, 3);
    }

    this.setState({
        users
    });
  }

  handleUserSelect = selectedOption => {
    this.setState({ shareToUser: selectedOption || null });
  }

  sendEmailViaUsername = () => {
    const { shareToUser } = this.state;
    if (shareToUser) {
      this.props.getUserInfo(shareToUser.value).then(user => {
        if (!user.email) {
          this.setState({ showAlert: true, alertMsg: 'There is no email on file for this user.  Please share via email instead if you know the email address for the user.' });
        } else {
          shareToUser.email = user.email;
          this.setState({shareToUser}, () => this.shareMovie());
        }
      });
    } else {
      this.setState({ showAlert: true, alertMsg: `Please enter critic's username.` }, () => setTimeout(() => this.setState({ showAlert: false }), 700));
    }
  }

  render() {
    const { showAlert, alertMsg, scrollList, showModal, selectedMovie, comment, movieComments, rating, type, showReviewModal, showCancelButton, movieToDelete, title, searchInput, foundMovies, modalType, showShareModal, toShareList, movieToShare, alertType, commentToDelete, movieToUnrate, shareToUser, users } = this.state;
    const { rateMovie2, editMovie, showThumbs, deleteMovie, movies, showRating, aveRating, releaseDate, showRating2, showAgree2, showDisagree2, addToWatchList, showVoteCount, showComments, showDeleteMovie, showAddComment, showShare, getFollowingMovies, deleteMovieComment, unrateMovie } = this.props;
    const userId = localStorage.getItem('username');
    const openModal = (selectedMovie) => {
      this.setState({ selectedMovie });
      setTimeout(() => {
        document.querySelector('#comment-input').focus()
      });
    };
    const closeModal = (event) => {
      event && event.preventDefault();
      this.setState({ selectedMovie: false });
    };
    const reversedCommentMovies = movies.map(movie => {
      movie.comments.reverse();
      return movie;
    });
    const customStyles = {
      content: {
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        width: '100%',
        height: '100%'
      }
    };

    const agreeDisagreeStyles = {
      content: {
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        width: '100%',
        height: '100%',
        position: 'static'
      },
      overlay: {
        padding: '15%'
      }
    };

    const shareModal = {
      content: {
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        maxHeight: '350px'
      }
    };

    return (
      <div className="movieListParent container p-0">
        <SweetAlert
          show={showAlert}
          title={alertMsg}
          showCancelButton={showCancelButton}
          onConfirm={() => {
            this.setState({ showAlert: false }, () => {
              if (alertType === 'deleteMovie') {
                deleteMovie(movieToDelete._id).then(() => getFollowingMovies({}))
              } else if (alertType === 'deleteComment') {
                deleteMovieComment({ movieId: commentToDelete.movieId, commentId: commentToDelete._id }).then(() => this.setState({ movieComments: movieComments.filter(c => c._id !== commentToDelete._id) }))
              } else if (alertType === 'deleteAgreeDisagree') {
                unrateMovie({ movieId: movieToUnrate._id, type: modalType.toLowerCase() }).then(() => this.setState({ scrollList: scrollList.filter(u => u !== userId) }));
              }
            });
          }}
          onCancel={() => this.setState({ showAlert: false })}
        />
        <Modal
          isOpen={Boolean(scrollList.length)}
          style={agreeDisagreeStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => this.setState({ scrollList: [] })}
        >
          <h5>{capitalizeFirstLetter(modalType)} List:</h5>
          <ul className="scroll-bar">
            {scrollList.map((p, i) => {
              return <li key={i}>
                <Link className='username' to={`/user/${p}`}>{p}</Link>
                {p === userId ? <i onClick={() => this.setState({ alertType: 'deleteAgreeDisagree', showAlert: true, showCancelButton: true, alertMsg: 'You are about to delete an agree, or disagree that you made. Would you like to proceed?' })} className="fas fa-trash-alt"></i> : ''}
              </li>
            })}
          </ul>
          {/*<button type="button" className="btn btn-outline-primary" onClick={() => this.setState({ scrollList: [] })}>Close</button>*/}
        </Modal>
        <Modal
          isOpen={showModal}
          style={this.state.modalType === 'comments' ? agreeDisagreeStyles : shareModal}
          shouldCloseOnOverlayClick
          onRequestClose={() => this.setState({ showModal: false })}
        >
          <div>
            <h5>Comment List:</h5>
            <ul className="scroll-bar">
              {movieComments.map((c, i) => {
                return (<li key={i}>
                  <Link className='username' to={`/user/${c.user}`}>{c.user}</Link>: {c.comment}
                  {c.user === userId ? <i onClick={() => this.setState({ commentToDelete: c, showAlert: true, alertType: 'deleteComment', showCancelButton: true, comment: c, alertMsg: 'You are about to delete a comment that you made. Would you like to proceed?' })} className="fas fa-trash-alt"></i> : ''}
                </li>)
              })}
            </ul>
          </div>
        </Modal>
        <Modal
          isOpen={showShareModal}
          style={customStyles}
          shouldCloseOnOverlayClick
          onRequestClose={() => this.setState({ showShareModal: false })}
        >
          <div className="text-center">
            <br/>
            <h3>Share Options</h3>
            <br/>
          <h4> To share your movie/show review by username, enter a critic's username below and click the send button.</h4>
          <div className="flex">
            <Select
                className="reactSelect black-text"
                value={shareToUser}
                onChange={this.handleUserSelect}
                onInputChange={this.handleSearchUsers}
                placeholder='Enter Username...'
                noOptionsMessage={() => null}
                isClearable={true}
                options={users}
                required
              />
              <button type="button" onClick={this.sendEmailViaUsername} className='btn btn-primary form-control-append ml-2'>Send</button>
            </div>
            <br />
            <h4> To share your movie/show review by email, enter one or more email addresses seperated by commas or spaces below and click the send button.</h4>
            <form className='input-group' onSubmit={this.shareMovie}>
              <input className='form-control blue-border text-center black-text' placeholder='user1@demo.com, user2@demo.com' required name="toShareList" value={toShareList} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
              <button className='btn btn-primary form-control-append ml-2'>Send</button>
            </form>
            <br />
            <h4>To share your movie/show review on Twitter or Facebook, click the Twitter or Facebook share button below.</h4>
            <div className="shareButtonsParent mt-2 mb-3" onClick={() => this.setState({ showShareModal: false })}>
              <FacebookShareButton
                url={`https://www.moviecriticapp.com/user/${userId}`}
                quote={`Just rated ${movieToShare.title} ${movieToShare.rating} out of 10. ${movieToShare.imdbRating ? `(IMDB: ${movieToShare.imdbRating})` : movieToShare.tmdbRating ? `(TMDB: ${movieToShare.tmdbRating})` : '' } \n\n MovieCritic Profile:
                ${window.location.origin}/user/${userId}`}
                className='shareButtons'
              >
                <FacebookIcon
                  size={75}
                  className='shareButtons'
                  round />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${window.location.origin}/user/${userId}`}
                title={`Just rated ${movieToShare.title} ${movieToShare.rating} out of 10.  ${movieToShare.imdbRating ? `(IMDB: ${movieToShare.imdbRating})` : movieToShare.tmdbRating ? `(TMDB: ${movieToShare.tmdbRating})` : '' } \n\n MovieCritic Profile:`}
                className='shareButtons'
              >
                <TwitterIcon
                  size={75}
                  className='shareButtons'
                  round />
              </TwitterShareButton>
            </div>
            <div>
              <input
                type="submit"
                value={`Close`}
                className="close-button btn btn-outline-primary"
                onClick={() => this.setState({ showShareModal: false })}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showReviewModal}
          style={customStyles}
          shouldCloseOnOverlayClick
          onRequestClose={() => this.setState({ showReviewModal: false })}
        >
          <h3 className="App mb-5">Rate Movie/Show</h3>
          <form onSubmit={this.handleReviewMovie}>
            <label>Title</label>
            <div>
              <Select
                value={title}
                onChange={this.handleTitleChange}
                inputValue={searchInput}
                onInputChange={this.handleSearchMovie}
                placeholder='Enter Title...'
                noOptionsMessage={() => null}
                isClearable={true}
                isDisabled={true}
                menuIsOpen={true}
                options={foundMovies}
              />
            </div>
            <div className="form-group mt-2">
              <label>Rating</label>
              <select
                className="form-control form-control-lg blue-border text-primary"
                type="text"
                name="rating"
                id="exampleRating"
                defaultValue={rating}
                required
                onChange={e => this.handleInputChange(e.target.name, e.target.value)}
              >
                <option value="">Select A Rating (1-10)</option>
                {[10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4, 3, 2, 1, 0].map((a, i) => {
                  return <option key={i} value={a}>{a === 0 ? 'Bomb' : a}</option>
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Type</label>
              <select
                className="form-control form-control-lg blue-border text-primary mb-5"
                name="type"
                defaultValue={type}
                required
                onChange={e => this.handleInputChange(e.target.name, e.target.value)}
              >
                <option value="">Select A Type (Movie/Show)</option>
                <option value="movie">Movie</option>
                <option value="show">Show</option>
              </select>
            </div>
            <input
              type="submit"
              value={`Rate Movie/Show`}
              className="btn btn-outline-primary"
            />
            <input
              type="submit"
              value={`Close`}
              className="btn btn-outline-primary ml-2"
              onClick={() => this.setState({ showReviewModal: false })}
            />
          </form>
        </Modal>
        <Modal
          isOpen={Boolean(selectedMovie)}
          style={customStyles}
          shouldCloseOnOverlayClick
          onRequestClose={() => this.setState({ selectedMovie: false })}
        >
          <form onSubmit={e => this.handleAddComment(e)}>
            <h3>Add Comment</h3>
            <textarea cols="50" rows="5" required name="comment" id="comment-input"
              className="form-control mb-3 mt-3" value={comment} onChange={e => this.handleInputChange(e.target.name, e.target.value)} />
            <button className="btn btn-outline-primary">Submit</button>
            <input
              type="submit"
              value="Close"
              className="btn btn-outline-primary ml-1"
              onClick={closeModal}
            />
          </form>
        </Modal>
        {reversedCommentMovies.map((movie, index) => {
          return (
            <div key={index} className="movieList row col-sm-12 mb-5">
              <div className="col-sm-7" key={index}>
                <div>
                  {capitalizeFirstLetter(movie.title)}
                </div>
                {showRating && <div>
                  {aveRating ?
                    `Average Rating Of Critics You Follow: ${movie.rating}` :
                    <span>
                      <Link to={`/user/${movie.userId}`}>
                        {capitalizeFirstLetter(movie.userId)}
                      </Link>'s Rating: {movie.rating === 0 ? 'Bomb' : movie.rating} &nbsp;
                      {movie.imdbRating ? <> (IMDB: {movie.imdbRating}) </> : (movie.tmdbRating ? <> (TMDB: {movie.tmdbRating}) </> : '')}
                      </span>
                  }
                </div>}
                {showVoteCount && <div>
                  Number of Critic Votes: {movie.voteCount}
                </div>}
                <div>
                  Type: {capitalizeFirstLetter(movie.type)} {!aveRating && <span>({<Moment format="YYYY">
                    {new Date(movie.releaseDate)}
                  </Moment>})</span>
                  }
                </div>
                {releaseDate && <div>
                  Released on <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
                    {new Date(movie.releaseDate)}
                  </Moment> EST
                </div>}
                {showRating2 && <div>
                  Rated On: <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
                    {new Date(movie.updatedAt)}
                  </Moment> EST
                  </div>}
                {showAgree2 && <div>
                  <Link to="#" onClick={() => this.setState({ scrollList: movie.agree, movieToUnrate: movie, modalType: 'Agree' })}>
                    {movie.agree.length} Agree:
                  </Link>
                  <Link className='username' to={`/user/${movie.agree[movie.agree.length - 1]}`}> {movie.agree[movie.agree.length - 1]} </Link>
                </div>}
                {showDisagree2 && <div><Link to="#" onClick={() => this.setState({ scrollList: movie.disagree, movieToUnrate: movie, modalType: 'Disagree' })}>
                  {movie.disagree.length} Disagree:</Link> {<Link className='username' to={`/user/${movie.disagree[movie.disagree.length - 1]}`}>{movie.disagree[movie.disagree.length - 1]}</Link>}
                </div>}
                {showComments && <div>
                  <Link to="#" onClick={() => this.handleShowComments(movie)}>
                    {movie.comments.length} Comments:
                    </Link> {movie.comments[0] &&
                    <span>
                      <Link className='username' to={`/user/${movie.comments[0].user}`}>
                        {movie.comments[0].user}
                      </Link> <span> - {movie.comments[0].comment}</span></span>}
                </div>}
                {showThumbs && <div>
                  <i onClick={() => this.handleRateMovie(movie, 'agree')} className="likeButton far fa-2x fa-thumbs-up"></i>
                  <i onClick={() => this.handleRateMovie(movie, 'disagree')} className="likeButton far fa-2x fa-thumbs-down ml-4"></i>
                </div>}
                <div>
                  {showAddComment && <button onClick={() => openModal(movie)} className="socialButton btn btn-outline-primary mr-2 mt-6p">Add Comment</button>}
                  {!movie.exist && addToWatchList &&
                    <button className="socialButton btn btn-outline-primary mt-6p" onClick={() => this.handleAddMovie(movie)}>Add To WatchList</button>
                  }
                </div>
                <div>
                  {editMovie && movie.userId === userId && <button onClick={() => editMovie(movie)} className="editButton btn btn-outline-primary mr-2 mt-6p" id="editButton" >Edit</button>}
                  {showDeleteMovie && movie.userId === userId && <button onClick={() => this.setState({ showAlert: true, alertType: 'deleteMovie', showCancelButton: true, movieToDelete: movie, alertMsg: 'Are you sure you want to delete this Movie/Show?' })} className="deleteButton btn btn-outline-primary mt-6p" id="deleteButton" >Delete</button>}
                  {rateMovie2 && movie.userId === userId && <button onClick={() => this.setState({ movieToReview: movie, title: { label: movie.title, value: movie.title }, type: movie.type, showReviewModal: true })} className="editButton btn btn-outline-primary ml-2 mt-6p" id="editButton">Rate</button>}
                  {showShare && movie.userId === userId && <button onClick={() => this.setState({ movieToShare: movie, showShareModal: true })} className="btn btn-outline-primary ml-2 mt-6p" >Share</button>}
                </div>
              </div>
              <div className="moviePoster col-sm-5">
                {movie.imgUrl && <img alt='' src={movie.imgUrl} />}
              </div>
            </div>)
        })}
      </div>
    )
      ;
  }
}



const mapStateToProps = (store) => {
  return {
    user: store.user,
    myTowatchList: (store.movie.movies || []).filter(m => !m.watched),
    movieError: store.movie.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMovie: id => dispatch(deleteMovie(id)),
    rateMovie: movie => dispatch(rateMovie(movie)),
    unrateMovie: movie => dispatch(unrateMovie(movie)),
    addMovie: movie => dispatch(addMovie(movie)),
    updateMovie: movie => dispatch(updateMovie(movie)),
    addMovieComment: body => dispatch(addMovieComment(body)),
    deleteMovieComment: body => dispatch(deleteMovieComment(body)),
    addNotification: movie => dispatch(addNotification(movie)),
    getUserMovies: username => dispatch(getUserMovies(username)),
    getFollowingMovies: (params) => dispatch(getFollowingMovies(params)),
    sendEmail: (emails, movie) => dispatch(sendEmail(emails, movie)),
    getAllUsers: () => dispatch(getAllUsers()),
    getUserInfo: username => dispatch(getUserInfo(username))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieList);
