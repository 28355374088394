import React, { Component } from 'react';

export default class Guide extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="row wideFix">
        <div className="Privacy logo-shift col-12">
          <div className="Feedback">
            <h2 className="App mb-5">Guide/Getting Started</h2>
            
            <h3 className='App'>MovieCritic Video Demo:</h3>
              <br/>
              <div className='videoDemoParent'>
              <iframe className='videoDemo' width="900" height="500" src="https://www.youtube.com/embed/Thb0Hfbx-48" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
             </div>
             <br/>
              <br/>
              <h3 className='App'>Getting Started:</h3>
              <h4>
              <br />
              Welcome to MovieCritic!  After registering a new account, you will be logged in to your new account
              and should see your "Home/Social Reviews" page.  This should include a user with name "demoaccount" that
              you are following, along with sample movie review(s).  You can unfollow "demoaccount" or any
              other user that you are following by clicking the trash icon next to demoaccount in your home page.
              You can navigate to other pages in the app by clicking the 3 black menu bars on the top right of the
              screen, which opens a menu with clickable links to other pages you can visit/view. The menu can be
              closed by clicking the "X" icon on the top right of the open menu. Please enjoy MovieCritic and
        contact us at info@moviecriticapp.com if there's anything we can do to help.<br />
              <br />
              </h4>
              <h3 className='App'>Typical First Steps:</h3>
              <h4>
              <br />
              1.  Register a free MovieCritic account.<br />
              2.  Review a few good new movies or shows.<br />
              3.  Discover new movies in the "Recommendations" section and click "Add To Watchlist" to watch later.<br />
              4.  Share your username with a friend or family member, get their username, and follow each other.<br />
              5.  Enable email alerts in "Profile Settings" to see when other users that follow you comment on, agree with, or disagree with your reviews.<br />
              <br />
              </h4>
              <h3 className='App'>Supported Browsers/Platforms:</h3>
              <h4>
              <br />
              Desktop Platforms: Windows, Mac, Linux, Chromebook<br/>
              Browsers: Chrome, Safari, Firefox<br/>
              Mobile Platforms: Android, iPhone<br/>
              <br/>
              <h4>Note - Speech recognition has been added for Chrome on desktop/android. <br/> 
                Speech recognition is not yet supported in other browsers.</h4>
              <br />
              </h4>
              <h3 className='App'> Privacy Settings:</h3>
              <h4>
              <br />
              Note - Your WatchList (ToWatch List) will always be private regardless of your chosen privacy setting.<br/>
              <br/>
              Public:<br/>
              Your reviews are visible to: Anyone with a link to your profile page.<br/>
              Your Username is Visible In Search Suggestions: When a user correctly enters the first 3 characters of your username or correctly enters your full email address.<br/>
              You can be sent a message by: Anyone with a MovieCritic account.<br />
              You can be followed by: Anyone with a MovieCritic account.<br />
              <br />
              Default: <br/>
              Your reviews are visible to: Only you and your followers on MovieCritic.<br/>
              Your Username is Visible In Search Suggestions: When a user correctly enters the first 3 characters of your username.<br/>
              You can be sent a message by: Anyone with a MovieCritic account.<br />
              You can be followed by: Anyone with a MovieCritic account.<br />
              <br />
              Private: <br/>
              Your reviews are visible to: Only you.<br/>
              Your Username is Visible In Search Suggestions: Never.<br/>
              You can be sent a message by: No other MovieCritic user.<br />
              You can be followed by: No other MovieCritic user.<br />
              <br />
              </h4>
              <h3 className='App'>Following New Users:</h3>
              <h4>
              <br />
              You can follow additional users by clicking the box titled "Enter Critic's Username", entering their
              name, and clicking the "Follow Critic" button.  You can unfollow/delete users that you are following
              (labeled "Following") or that are following you (labeled "Followers") by clicking the trash icon next
              to their username in the follower/following section of your home page. Your "Home/Social Reviews" page
              is the main section of the app/website where you can add and view movie reviews that you and other users
        that you follow have added.<br />
              <br />
              </h4>
              <h3 className='App'>Adding New Movies:</h3>
              <h4>
              <br />
              You can add a new movie review in your home page by clicking the "Review New Movie/Show" button,
              entering the movie/show title, selecting your rating from 0-10, selecting the type (movie or show),
              and clicking the "Add Movie/Show Review" button.  You can also add a new movie in the
              "My Reviews/Add Movie" section, but this page only includes movies that you alone have reviewed
              as opposed to the "Home/Social Reviews" page, which also shows reviews by users that you are
        following.<br />
              <br />
              </h4>
              <h3 className='App'>Movie Recommendations:</h3>
              <h4>
              <br />
              The "Movie Recommendations" page allows users to discover new movies and shows.  By default, the
              "Movie Recommendations" page is set to search for the latest movies that have an average rating of
              at least 7 from an online movie/show database source called TMDB (The Movie Database).  These settings
              can be changed to search for shows instead of movies by selecting "Show" in the "Type" dropdown menu.
              The settings for mininum rating, type, source, and sort by can all be customized by the user.  Any
              movie/show that looks interesting can be added to your ToWatchList by clicking the "Add To WatchList"
        button below the movie/show in the "Movie Recommendations" page.<br />
              <br />
              </h4>
              <h3 className='App'>ToWatchList:</h3>
              <h4>
              <br />
              The ToWatchList page is where the user can add movies/shows that they would like to watch when they
              get a chance.  To add a movie/show to your ToWatchList, enter the name of the movie/show, select
              the type, and click "Add Movie/Show".  Once a movie/show in your ToWatchList is seen, you can
              review/rate it by clicking the "Rate" button below the movie/show in your ToWatchList section.
              Then select the rating and type from the dropdown menu and click the "Rate Movie/Show" button to
        add the movie/show to your reviews.<br />
              <br />
              </h4>
              <h3 className='App'>Notifications:</h3>
              <h4>
              <br />
              The Notifications page allows the user to see notifications that may be relevant to them.
              There are notifications when a new user follows you, when you follow another user, when a
              user you follow adds a new movie, and when another user that is following you comments,
              agrees, or disagrees with a movie/show review of yours.  By default, email alerts are not
              sent to the user for each notification, but this can be changed in the "Profile Settings"
              page by clicking the "Off" button to the right of "Notification Emails" so that it
        switches to "On".<br />
              <br />
              </h4>
              <h3 className='App'>Import/Export Reviews:</h3>
              <h4>
              <br />
              The "Import/Export Reviews" section of profile settings allows a user to download their own reviews in json format
              by clicking the "Export" button.  These reviews can be imported to a user's existing
              or newly created MovieCritic account by clicking the "Choose File" button, selecting the
        exported reviews file in json format, and clicking the "Import" button.<br />
              <br />
              </h4>
              <h3 className='App'>Profile Page:</h3>
              <h4>
              <br />
              The "Profile Page" displays a user's username, profile picture, a button to follow the
              user, a button to download the user's reviews, a button to register a new MovieCritic
            account, and the user's recent movie/show reviews.<br />
              <br />
              </h4>
              <h3 className='App'>Profile Settings:</h3>
              <h4>
              <br />
              The "Profile Settings" page is where the user can change/update several settings.  This
              includes the ability to select their profile privacy level, the ability to recieve email alerts
              for each new notification, the ability to change/select your profile image, the ability
              to update your email address, and the ability to update your password.
              <br />
            </h4>
          </div>
        </div>
      </div>
    );
  }
}




